import React from "react"
import './css/createhotel.css';
import './css/App.css';
import { getciaw } from "../dbconnector/connector";
import { setErrorMessage } from "../tools/errorhandling";
import { useState } from 'react';
import { useEffect } from 'react';
import hotelcss from './css/hotelhotellist.module.css';





const Ciaw = (props) => {

    const [ciaw, setCiaw] = useState([]);
    const [array, setArray] = useState([]);


    useEffect(() => {

        const response = getciaw(Date.now())
        console.log(response)

        response.then(res => {

            console.log(res)
            createciawlist(res);


            setCiaw(res)

        });

    }, []);

    const createciawlist = (ciaw) => {
        setArray([])
        ciaw.forEach((item, index) => {
                    const obj = JSON.parse(item.response)[0];
                    const payload = JSON.parse(item.message);
        if(obj.createdPresenceRegistration){
            if(obj.createdPresenceRegistration===null){
                console.log(payload.items[0])
                setArray(pre => [...pre, <div  className={hotelcss.ciawlinkError} >
                    <p className="overflow">failed</p>
                    <p className="overflow">{payload.items[0].type}</p>
                    <p className="overflow">{new Date(item.senddate).toLocaleDateString()} {new Date(item.senddate).toLocaleTimeString()}</p>
                    <p className="overflow">ssin: {payload.items[0].ssin}</p>
                    <p className={hotelcss.overflowresponse}>{obj.notCreatedPresenceRegistration.errorList[0].errorDescription}</p>                
                </div>]
                )

               }else{
                
                setArray(pre => [...pre, <div  className={index % 2 === 0 ? hotelcss.ciawlinkVarient1 : hotelcss.ciawlinkVarient2} >
                    <p className="overflow">success</p>
                    <p className="overflow">{obj.createdPresenceRegistration.type}</p>
                    <p className="overflow">{new Date(item.senddate).toLocaleDateString()} {new Date(item.senddate).toLocaleTimeString()}</p>
                    <p className="overflow">{obj.createdPresenceRegistration.worker.givenName} {obj.createdPresenceRegistration.worker.familyName}</p>
                   <p className="overflow">{item.response}</p>
                   
                </div>]
                )
               }

        }else{
            setArray(pre => [...pre, <div  className={hotelcss.ciawlinkError} >
                <p className="overflow">failed</p>
                <p className="overflow">{}</p>
                <p className="overflow">{new Date(item.senddate).toLocaleDateString()} {new Date(item.senddate).toLocaleTimeString()}</p>
                <p className="overflow">{item.fullname}</p>
                <p className={hotelcss.overflowresponse}>{item.response}</p>               
            </div>]
            )
        }
          
            
        })
    }

    const filterlist = () => {
        const date = document.getElementById("dateinput").value;
        console.log(date)
        if (date === "") {
            setErrorMessage("please fill in a date")
            return;
        }
        const response = getciaw(date)
        response.then(res => {
            console.log(res)
            setCiaw(res)
            createciawlist(res);
           
        })
    }


    return (
        <>
            <div id="main">
                <header>
                    <div id="errordiv" className='error'></div>
                </header>
                <div id="filters" className="form" >
                <label className='formtitleContentBox'>filter instellingen</label>
                <br></br>
                <div className="contentbox">
                   
                    <div className="formElement">
                        <label className='labelcolor'>
                            day:
                        </label>
                        <input id="dateinput" type='Date'></input>
                    </div>
                    <div className="formElement">
                        <label className='labelcolor' />
                        <button className='buttoncontainer filterButton' type='button' id="filterbutton" onClick={filterlist}>filter</button>
                    </div>
                    </div>

                    <br></br>
                    </div>
                <div id="headertable" className={hotelcss.ciawtableheader}><p id="status">status</p><p id="type">type</p><p id="senddate">senddate</p><p id="Name">Name</p><p id="Response">response</p></div>
                
                <div id='ciawlist' className='list'>
                    <div id="array" >{array}</div>
                </div>
            </div>

        </>
    )

}

export default Ciaw