
import React from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const Startpage = (props) => {
  const { loggedIn, email} = props
  const navigate = useNavigate()

  const navigatetopwr = ()=>{
    navigate("/passReset")
  }

  const onButtonClick = () => {
    navigate("/login");
  }

  if (loggedIn) {

      navigate("/")
    
  }
  return (
    <div className="mainContainer">
      <div className={'titleContainer'}>
        <div>SHC</div>
      </div>
      <div>Welcome to myshc.net</div>
      <div className={'buttonContainer'}>
        <input
          className='inputButton'
          type="button"
          onClick={()=>onButtonClick()}
          value={loggedIn ? 'Log out' : 'Log in'}
        />
        <Button onClick={navigatetopwr}>Reset password</Button>
      </div>
    </div>
  )
}
export default Startpage;