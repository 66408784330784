import './css/tabs.css';
import hotelcss from './css/hotelhotellist.module.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import React from "react";
import Select from 'react-select';
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    sendhoteldata, getallhotels, gethotel,
    updateHotelData, getRooms, getRoomsTypes,
    getserverurl, createroom, createroomtype,
    editkamer, deleteRoom, deleteRoomtype, editkamerType, saveCleaningtask, getCleaningtasks, editCleaningTask, deleteCleaningTask,
    saveCleaningtype, getCleaningtypes, editCleaningType, deleteCleaningType, savelinkedTasks,getlinkedtasks
} from "../dbconnector/connector";
import { useParams } from "react-router-dom";
import { pdfjs } from 'react-pdf';
import { useEffect } from 'react';
import { setErrorMessage } from "../tools/errorhandling";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { confirmAlert } from 'react-confirm-alert';

import Button from 'react-bootstrap/Button';
import { MdEdit, MdDelete } from "react-icons/md";
import { HiOutlineChevronDown } from "react-icons/hi";
import { HiOutlineChevronUp } from "react-icons/hi";
import { MdCleaningServices } from "react-icons/md";
import { MdOutlineCheck } from "react-icons/md";
import { MdOutlineCancel } from "react-icons/md";
import { t } from 'i18next';

//<MdOutlineCancel />
//<MdOutlineCheck />

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();


const Createhotel = () => {

    const [contract, setContract] = useState({ value: '' })
    const [hotel, setHotel] = useState('');

    const navigate = useNavigate()

    const onSubmit = (e) => {
        e.preventDefault();

        const response = sendhoteldata({ ...hotel, Contract: contract })

        response.then(res => {
            ////console.log(res)
            if (res.status === 200) {
                navigate('/hotels')
            } else {
                setErrorMessage(res.status + ": " + res.statusText)
            }
        });
    }

    const handleitemchange = (e) => {
        setHotel((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
        //console.log(hotel)
    }

    const handleCancel = () => {
        navigate('/hotels');
    }
    const changecheckbox = (e) => {

        setHotel((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.checked
            }

        })

    }
    return (
        <>

            <div id="main">
                <header>
                    <div id="errordiv" className='error'></div>
                </header>
                <div id="return" className="form">
                    <Button type="button" id="cancelButton" class="Savebutton btn btn-primary margin-right" onClick={() => navigate("/hotels")} >Return to list</Button>
                </div>
                <form className="form" onSubmit={onSubmit} autocomplete="off">
                    <label className='formtitle'>
                        Hotel Details '{hotel.Name}'
                    </label>
                    <div className="formchild">
                        <label className='labelhotel'>
                            Actief:
                        </label>
                        <input id="hotelActive" type='CheckBox' className="checkbox " value={hotel.active} name="active" onChange={changecheckbox} checked={hotel.active}></input>
                    </div>
                    <div className="formchild">
                        <label className='labelhotel'>
                            Name:
                        </label>
                        <input id="hotelnaam" name="Name" required className="normalinput" value={hotel.Name} onChange={handleitemchange} type='text'  ></input>
                        <label className='labelhotel'>
                            Department number:
                        </label>
                        <input id="afdeling" name="nr_afd" required className="normalinput" value={hotel.nr_afd} onChange={handleitemchange} type='number' ></input>
                    </div>
                    <div className="formchild">
                        <label className='labelhotel'>
                            BTW:
                        </label>
                        <input id="btw" className="doublegrid" required type='text' name="btw_nummer" value={hotel.btw_nummer} onChange={handleitemchange} ></input>
                    </div>

                    <div className="formchild">
                        <label className='labelhotel'>
                            Street name:
                        </label>
                        <input id="straatnaam" type='text' required className="doublegrid" name="straatnaam" value={hotel.straatnaam} onChange={handleitemchange}  ></input>
                        <label className='labelhotel'>
                            House number:
                        </label>
                        <input id="huisnummer" type='number' required className="normalinput" name="huisnummer" value={hotel.huisnummer} onChange={handleitemchange} ></input>
                        <label className='labelhotel'>
                            Bus:
                        </label>
                        <input id="bus" type='text' className="normalinput" name="bus" value={hotel.bus} onChange={handleitemchange} ></input>

                        <label className='labelhotel'>
                            Zipcode:
                        </label>
                        <input id="zipcode" type='number' required className="normalinput" name="zipcode" value={hotel.zipcode} onChange={handleitemchange} ></input>
                        <label className='labelhotel'>
                            City:
                        </label>
                        <input id="gemeente" type='text' required className="normalinput" name="gemeente" value={hotel.gemeente} onChange={handleitemchange} ></input>
                        <label className='labelhotel'>
                            Country:
                        </label>
                        <input id="land" type='text' required className="normalinput" name="land" value={hotel.land} onChange={handleitemchange} ></input>

                    </div>
                    <div className="formchild">
                        <label className='labelhotel'>
                            GPS location:
                        </label>
                        <input id="gpslocatie" type='text' className="doublegrid" name="gps_locatie" value={hotel.gps_locatie} onChange={handleitemchange} ></input>
                    </div>
                    <div className="formchild">
                        <label className='labelhotel'>
                            Contractual reference:
                        </label>
                        <input id="contractrelationNumber" type='text' className="doublegrid" name="contractrelationNumber" value={hotel.contractrelationNumber} onChange={handleitemchange} disabled></input>
                    </div>
                    <div className="formchild">
                        <label className='labelhotel'>
                            Contact:
                        </label>
                        <input id="contactperson" type='text' className="doublegrid" name="contactperson" value={hotel.contactperson} onChange={handleitemchange} ></input>
                        <label className='labelhotel'>
                            Contact number:
                        </label>
                        <input id="contactnumber" type='tel' className="doublegrid" name="contactnumber" value={hotel.contactnumber} onChange={handleitemchange} ></input>
                    </div>
                    <div id="uploaddiv" className="formchild">
                        <label className='labelhotel'>
                            Upload Contract:
                        </label>
                        <input id="uploadContract" className="normalinput" type='file' accept='.pdf' onChange={(e) => setContract({ value: e.target.files[0] })}></input>
                    </div>
                    <div className="formchild">
                        <label className='labelhotel'>
                            Note:
                        </label>
                        <textarea id="Note" type='textarea' className="doublegrid" name="note" value={hotel.note} onChange={handleitemchange}></textarea>
                    </div>
                    <div id="saveDiv" className="two_Buttons">
                        <Button id="saveButton" type="submit" className='Savebutton'>Save</Button>
                        <Button id="cancelButton" className='Savebutton' onClick={handleCancel}>Cancel</Button>
                    </div>
                </form>


            </div>
        </>

    )
}

const Viewhotels = () => {

    const [hotels, sethotels] = useState([]);

    const navigate = useNavigate();

    //console.log("Createhotelslist")



    useEffect(() => {



        try {
            const hotels = getallhotels();
            hotels.then((hotellist) => {
                //console.log(hotellist)
                if (hotellist.status === 200) {
                    sethotels(hotellist.items)
                    //

                    const ZoekOpNaam = document.getElementById("ZoekOpNaam");
                    const ZoekOpAfNr = document.getElementById("ZoekOpAfNr");
                    const active = document.getElementById("activehotel");
                    active.addEventListener("click", function (e) {
                        filter(hotellist)
                    })

                    ZoekOpNaam.addEventListener("input", function (e) {
                        filter(hotellist)
                    })

                    ZoekOpAfNr.addEventListener("input", function (e) {
                        filter(hotellist)
                    })
                    active.checked = "true"
                    Createhotelslist(hotellist.items, navigate);

                } else {
                    setErrorMessage("Error while retrieving hotels")
                }

            })

        } catch (error) {
            setErrorMessage("Error while retrieving hotels")
        }


    }, []);

    function filter(hotellist) {
        const ZoekOpNaam = document.getElementById("ZoekOpNaam")
        const ZoekOpAfNr = document.getElementById("ZoekOpAfNr")
        const filteredhotels = hotellist.items.filter(element => {
            if (element.Name.toLowerCase().includes(ZoekOpNaam.value.toLowerCase()) && element.nr_afd.toString().includes(ZoekOpAfNr.value)) {
                return element
            }

        })
        Createhotelslist(filteredhotels, navigate)
    }

    const Createhotelslist = (hotellist, navigate) => {


        let listForHotels = document.getElementById("hotelsList");
        if (listForHotels === null) {
            listForHotels = document.createElement("div");

            listForHotels.id = "hotelsList";

            const upperdiv = document.getElementById("main");

            upperdiv.appendChild(listForHotels);

        } else {
            listForHotels.innerHTML = ""


        }

        const hotellistactivefilter = hotellist.filter(element => !document.getElementById("activehotel").checked ? element.active === 0 : element.active === 1)

        hotellistactivefilter.forEach((hotel, index) => {

            const hotellink = document.createElement("div");
            //addname element to div
            const IdP = document.createElement("p");
            IdP.className = hotelcss.textalignright;
            IdP.innerText = hotel.nr_afd;
            hotellink.appendChild(IdP);
            const nameP = document.createElement("p");
            nameP.innerText = hotel.Name;
            hotellink.appendChild(nameP);
            const description = document.createElement("p");
            description.innerText = hotel.straatnaam + " " + hotel.huisnummer + ", " + hotel.zipcode + " " + hotel.gemeente;
            hotellink.appendChild(description);





            //dinamicly add classname
            if (index % 2 === 0) {
                hotellink.className = hotelcss.linkVarient1
            } else {
                hotellink.className = hotelcss.linkVarient2
            }
            hotellink.addEventListener('click', function (e) {
                navigate("/hotels/" + hotel.Hotel_ID);
            });

            listForHotels.appendChild(hotellink)
        });
    }


    return (
        <>
            <div id="main">
                <header>
                    <div id="errordiv" className='error'>
                    </div>
                </header>
                <div id="newuser" className="form">
                    <Button id="addhotelsbutton" onClick={() => (navigate("/createhotel"))} > Create hotel</Button>
                </div>
                <div id="filters" className="form" >
                    <label className='formtitleContentBox'>filter instellingen</label>
                    <br></br>
                    <div className="contentbox">
                        <div className="formElement">
                            <label className='labelcolor'>
                                Actief:
                            </label>
                            <input type="checkbox" id="activehotel" className="checkbox"></input>
                        </div>
                        <div className="formElement">
                            <label className='labelcolor'>
                                Hotel Naam:
                            </label>
                            <input type="text" id="ZoekOpNaam" placeholder="Search"></input>
                        </div>
                        <div className="formElement">
                            <label className='labelcolor'>
                                Hotel Nr:
                            </label>
                            <input type="text" id="ZoekOpAfNr" placeholder="Search"></input>
                        </div>

                    </div>
                    <div id="headertabled" className={hotelcss.tableheader}><p id="status">Dep.nummer</p><p id="type">Name</p><p id="senddate">Address</p></div>

                    <div id="hotelsList" className="list">
                    </div>
                </div>
            </div>

        </>

    )
}




const HotelView = () => {
    const navigate = useNavigate()
    const [contract, setContract] = useState({ value: '' })
    const [hotel, setHotel] = useState({});
    const [kamers, setKamers] = useState({});
    const [kamerTypes, setKamerTypes] = useState({});
    const [addkamer, setaddkamer] = useState({});
    const [kamertypesarray, setkamertypesarray] = useState([]);
    const [kamersarray, setkamersarray] = useState([]);
    const [cleaningtaskarray, setcleaningtaskarray] = useState([]);
    const [cleaningtaskstoassignarray, setcleaningtaskstoassignarray] = useState([]);
    const [cleaningTasks, setCleaningTasks] = useState({});
    const [cleaningtypearray, setcleaningtypearray] = useState([]);
    const [CleaningTypes, setCleaningTypes] = useState({});
    const [toUpdate, setToUpdate] = useState({});
    const [iconDisabled, setIconDisabled] = useState(false);
    const [tabsDisabled, setTabsDisabled] = useState(false);
    const [linkedtasks, setLinkedtasks] = useState({});


    const [selectedOption, setSelectedOption] = useState(null);

    let { id } = useParams();
    // const navigate = useNavigate()







    const addKamerfield = () => {

        setIconDisabled(true);
        setTabsDisabled(true);
        //console.log("in kamer field")
        let list = document.getElementById("roomslist");

        const kamerdiv = document.createElement("div");
        //addname element to div
        const kamernummer = document.createElement("input");
        kamernummer.onchange = (e) => setaddkamer({ ...addkamer, kamernummer: e.target.value })
        kamernummer.className = "inputTable";
        kamernummer.type = "text";
        kamernummer.id = "kamernummerinput";
        kamernummer.focus();
        kamerdiv.appendChild(kamernummer);

        const Type = document.createElement("select");
        Type.onchange = (e) => setaddkamer({ ...addkamer, Type: e.target.value })
        kamerdiv.appendChild(Type);

        Type.id = "kamertypeid"

        kamerdiv.className = hotelcss.linkVarientInput
        const kameraaray = document.getElementById("kamersarray")

        kameraaray.insertBefore(kamerdiv, kameraaray.firstChild);


        for (const type of kamerTypes) {
            const option = document.createElement("option")
            option.innerText = type.Naam;
            option.value = type.id;
            Type.appendChild(option)

        }

        const IconSave = document.getElementById("IconSave").cloneNode(true);
        const IconCancel = document.getElementById("IconCancel").cloneNode(true);

        const addbut = document.createElement("Button");
        addbut.appendChild(IconSave)
        addbut.className = "AddButtonTable";
        kamerdiv.id = "inputdiv"
        kamerdiv.appendChild(addbut);
        addbut.onclick = handleaddkamer;

        const cancelbutton = document.createElement("Button");
        cancelbutton.appendChild(IconCancel)
        cancelbutton.className = "AddButtonTable";
        cancelbutton.id = "tocancel"
        kamerdiv.appendChild(cancelbutton);
        cancelbutton.onclick = () => handleCancelRoomnewTask(id)
    }

    useEffect(() => {
        gethotel(id).then(result => {
            //console.log(result)

            if (result.status === 200) {
                setHotel(result.hotel);
                refreshkamers()
                refreshkamertypes()
                refreshcleaningtasks()
                refreshcleaningtypes()
            } else {
                setErrorMessage("could not retrieve Hotel data")
            }
        });

        document.getElementById("viewcontractButton").style.display = "grid";
        document.getElementById("uploaddiv").style.display = "none";

        document.getElementById("editDiv").style.display = "grid";
        document.getElementById("saveDiv").style.display = "none";

    }, []);

    const refreshkamers = () => {
        //console.log("in get objects")
        getRooms(hotel.Hotel_ID).then(res => {
            if (res.status === 200) {
                setKamers(res.items)
                kamerstab(0, res.items);
            } else {
                setErrorMessage("failed to retrieve rooms")
            }

        })
    }
    const refreshkamertypes = () => {
        setkamertypesarray([]);

        getRoomsTypes(hotel.Hotel_ID).then(res => {
            if (res.status === 200) {
                setKamerTypes(res.items)
            } else {
                setErrorMessage("failed to retrieve rooms types")
            }

        })
    }

    const refreshcleaningtasks = () => {
        if (hotel.Hotel_ID) {
            getCleaningtasks(hotel.Hotel_ID).then(res => {
                if (res.status === 200) {
                    res.json().then(body => {
                        //console.log('cleaningtasks:', body.items)
                        setCleaningTasks(body.items)
                    })
                } else {
                    setErrorMessage(res.statusText)
                }

            })
        }
    }

    const refreshcleaningtypes = () => {
        if (hotel.Hotel_ID) {
            getCleaningtypes(hotel.Hotel_ID).then(res => {
                if (res.status === 200) {
                    res.json().then(body => {
                        //console.log('cleaningtypes:', body.items)
                        console.log(body.items);
                        setCleaningTypes(body.items)                        
                    })
                } else {
                    setErrorMessage(res.statusText)
                }

            })
        }
    }

    useEffect(() => {


        if (hotel.Hotel_ID) {
            getRooms(hotel.Hotel_ID).then(res => {
                if (res.status === 200) {
                    setKamers(res.items)
                } else {
                    setErrorMessage("failed to retrieve rooms")
                }


            })

            getRoomsTypes(hotel.Hotel_ID).then(res => {

                if (res.status === 200) {
                    //console.log('kamertypes:', res.items)
                    setKamerTypes(res.items)
                } else {
                    setErrorMessage("failed to retrieve roomtypes")
                }

            })
            getCleaningtasks(hotel.Hotel_ID).then(res => {

                if (res.status === 200) {

                    res.json().then(body => {
                        //console.log('cleaningtasks:', body.items)
                        setCleaningTasks(body.items)
                    })

                } else {
                    setErrorMessage(res.statusText)
                }

            })

            getCleaningtypes(hotel.Hotel_ID).then(res => {
                if (res.status === 200) {
                    res.json().then(body => {
                        //console.log('cleaningtypes:', body.items)
                        setCleaningTypes(body.items)
                    })

                } else {
                    setErrorMessage(res.statusText)
                }

            })


        }

    }, [hotel]);



    const kamerstab = (index, items = false) => {
        if (index === 0) {
            if (kamers[0]) {
                setTimeout(() => {
                    //console.log("Delayed for 1 second.");
                    if (items !== false) {
                        //console.log(items)
                        //Createkamerslist(items, "roomslist")

                    } else {
                        //Createkamerslist(kamers, "roomslist")
                    }

                }, "10");

            }

        }
        if (index === 1) {
            if (kamerTypes[0]) {
                setTimeout(() => {
                    if (items !== false) {
                        //console.log(items)
                        //Createkamertypeslist(items, "typeslist")
                    } else {
                        // Createkamertypeslist(kamerTypes, "typeslist")
                    }
                }, "10");

            }

        }

    }
    const handleitemchange = (e) => {
        setHotel((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }

        })

        setToUpdate((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }

        })
        //console.log(hotel)
    }
    const handleEdit = () => {
        setIconDisabled(true);
        setTabsDisabled(true)
        document.getElementById("hotelnaam").disabled = false;
        document.getElementById("contractrelationNumber").disabled = false;
        document.getElementById("bus").disabled = false;
        document.getElementById("afdeling").disabled = false;
        document.getElementById("btw").disabled = false;
        document.getElementById("straatnaam").disabled = false;
        document.getElementById("huisnummer").disabled = false;
        document.getElementById("zipcode").disabled = false;
        document.getElementById("gemeente").disabled = false;
        document.getElementById("land").disabled = false;
        document.getElementById("gpslocatie").disabled = false;
        document.getElementById("Note").disabled = false;
        document.getElementById("saveButton").innerText = "Save";
        document.getElementById("contractdiv").style.display = "none";
        document.getElementById("uploaddiv").style.display = "grid";
        document.getElementById("editDiv").style.display = "none";
        document.getElementById("saveDiv").style.display = "grid";
        document.getElementById("contactnumber").disabled = false;
        document.getElementById("contactperson").disabled = false;
        document.getElementById("hotelActive").disabled = false;

    }
    const handleCancel = () => {
        window.location.reload();

    }


    const handleSave = (e) => {
        e.preventDefault();

        setIconDisabled(false);
        setTabsDisabled(false)

        if (contract.value.name) {
            updateHotelData({ ...toUpdate, Contract: contract }, hotel.Hotel_ID).then(() => {
                //console.log("/hotels/" + hotel.Hotel_ID)
                window.location.reload();
            })
        } else {
            updateHotelData({ ...toUpdate }, hotel.Hotel_ID).then(() => {
                //console.log('/hotels/' + hotel.Hotel_ID)
                window.location.reload();
            })

        }

    }

    const openContract = () => {
        window.open(getserverurl() + hotel.Contract, "_blank")
    }

    const handleaddkamer = () => {
        setIconDisabled(false);
        setTabsDisabled(false)
        const roomnumer = document.getElementById("kamernummerinput").value
        const kamertypid = document.getElementById("kamertypeid").value
        const hotelid = hotel.Hotel_ID;
        createroom(roomnumer, kamertypid, hotelid).then((res) => {
            refreshkamers();

            document.getElementById("kamersarray").removeChild(document.getElementById("inputdiv"));
        })

    }

    const handleaddkamerType = () => {
        const Kamertypename = document.getElementById("kamernummerinput").value
        const hotelid = hotel.Hotel_ID;

        setIconDisabled(false);
        setTabsDisabled(false)
        createroomtype(Kamertypename, hotelid).then((res) => {
            if (res.status === 200) {
                res.json().then(body => {
                    setKamerTypes(pre => body)
                    document.getElementById("kamerstypeaaray").removeChild(document.getElementById("toberemoved"));
                })
            } else {
                setErrorMessage(res.statusText)
            }

        })
    }


    const handleaddCleaningTask = () => {
        setIconDisabled(false);
        setTabsDisabled(false)
        const taskDescription = document.getElementById("cleaningtaskdescription").value
        const hotel_Id = hotel.Hotel_ID;
        const taskDuration = 0;

        //console.log("cleaningtypedescription = " + taskDescription)
        //console.log("hotelid = " + hotel_Id)
        //console.log("cleaningtypeduration = " + taskDuration)

        const promise = saveCleaningtask({ taskDescription, hotel_Id, taskDuration })

        promise.then((response) => {
            if (response.status === 200) {
                response.json().then((body) => {
                    //console.log(body.items);
                    document.getElementById("cleaningtaskarray").removeChild(document.getElementById("toberemoved"))
                    setCleaningTasks(body.items)
                    setcleaningtaskarray([]);
                    refreshcleaningtasks();
                })
            }
            else {
                setErrorMessage(response.statusText);
            }
        })

    }


    const handleaddCleaningType = () => {
        setIconDisabled(false);
        setTabsDisabled(false)
        const description = document.getElementById("cleaningtypedescription").value
        const hotel_Id = hotel.Hotel_ID;
        const duration = document.getElementById("cleaningtypeduration").value;
        const roomtype_id = document.getElementById("RoomType").value;

        //console.log("cleaningtypedescription = " + description)
        //console.log("hotelid = " + hotel_Id)
        //console.log("cleaningtypeduration = " + duration)


        const promise = saveCleaningtype({ description, hotel_Id, duration, roomtype_id })

        promise.then((response) => {
            if (response.status === 200) {
                response.json().then((body) => {

                    document.getElementById("cleaningtypearray").removeChild(document.getElementById("toberemoved"))
                    setCleaningTypes(body.items)
                    setcleaningtypearray([]);
                    refreshcleaningtypes();

                })
            }
            else {
                setErrorMessage(response.statusText);
            }
        })

    }

    const addKamertypefield = () => {
        setIconDisabled(true);
        setTabsDisabled(true)

        //Createkamertypeslist(kamers, "typeslist")

        let list = document.getElementById("typeslist");

        const kamerdiv = document.createElement("div");
        //addname element to div
        const kamernummer = document.createElement("input");
        kamernummer.className = "RTlinkVarientInput";
        kamernummer.type = "text";
        kamernummer.id = "kamernummerinput";

        kamerdiv.appendChild(kamernummer);


        kamerdiv.className = hotelcss.RTlinkVarientInput
        const kamerTypeAray = document.getElementById("kamerstypeaaray")

        kamerTypeAray.insertBefore(kamerdiv, kamerTypeAray.firstChild);

        const IconSave = document.getElementById("IconSave").cloneNode(true);
        const IconCancel = document.getElementById("IconCancel").cloneNode(true);

        const addbut = document.createElement("Button");
        addbut.appendChild(IconSave)
        addbut.className = "AddButtonTable";
        kamerdiv.appendChild(addbut);
        addbut.onclick = () => handleaddkamerType();
        kamerdiv.id = "toberemoved";
        kamernummer.focus();

        const cancelbutton = document.createElement("Button");
        cancelbutton.appendChild(IconCancel)
        cancelbutton.className = "AddButtonTable";
        cancelbutton.id = "tocancel"
        kamerdiv.appendChild(cancelbutton);
        cancelbutton.onclick = () => handleCancelRoomTypenewTask(id)


    }

    const addCleaningtaskfield = () => {
        setIconDisabled(true);
        setTabsDisabled(true)
        const cleaningdiv = document.createElement("div");
        //addname element to div
        const cleaningtaskdescription = document.createElement("input");
        cleaningtaskdescription.className = hotelcss.CTinput;
        cleaningtaskdescription.type = "text";
        cleaningtaskdescription.id = "cleaningtaskdescription";
        cleaningdiv.appendChild(cleaningtaskdescription);


        cleaningdiv.className = hotelcss.CTlinkVarientInput
        const cleaningtaskarray = document.getElementById("cleaningtaskarray")

        cleaningtaskarray.insertBefore(cleaningdiv, cleaningtaskarray.firstChild);


        const IconSave = document.getElementById("IconSave").cloneNode(true);
        const IconCancel = document.getElementById("IconCancel").cloneNode(true);

        const addbut = document.createElement("Button");
        addbut.appendChild(IconSave)
        addbut.className = "AddButtonTable";

        cleaningdiv.appendChild(addbut);
        addbut.onclick = () => handleaddCleaningTask()
        cleaningdiv.id = "toberemoved"
        cleaningtaskdescription.focus()

        const cancelbutton = document.createElement("Button");
        cancelbutton.appendChild(IconCancel)
        cancelbutton.className = "AddButtonTable";
        cancelbutton.id = "tocancel"
        cleaningdiv.appendChild(cancelbutton);
        cancelbutton.onclick = () => handleCancelCleaningTasknewTask(id)

    }
    const createcleaningtasksarray = async  (id) => {
        setcleaningtaskstoassignarray([]);
        const response = await getlinkedtasks(id)
            if(response.status === 200){
                const body = await response.json()
                    enterlinkedTasks(body.items)
                    if (cleaningTasks.length > 0) {   
                        setcleaningtaskstoassignarray([]);                    
                            setLinkedtasks((prev) => {
                                if (!prev) prev = {};
                                console.log(cleaningTasks)
                                cleaningTasks.forEach((item, index) => {                            
                                    console.log("prev",prev)                              
                                        setcleaningtaskstoassignarray(pre => [...pre,
                                            <div id={item.Id} className={index % 2 === 0 ? hotelcss.CTypemultiselect1 : hotelcss.CTypemultiselect2} >
                                                <input type="checkbox" className={hotelcss.CTypemultiselectcheckbox} id={item.Id} checked={prev[item.Id]} name={item.Id} onChange={(e)=>changetaskcheckbox(e,id)} />
                                                <p>{item.taskDescription}</p>
                                            </div>
                                        ])       
      
                
                                    })
                                })

                            }
                
            }
  
    }

    function enterlinkedTasks(tasks){
        setLinkedtasks({})
        tasks.forEach(task => {
            setLinkedtasks((prev) => {
                return {
                    ...prev,
                    [task.Id]: true
                }
            })
        })
    }

    const handleassignCleaningTasks = (id,test) => {



        if (test === undefined) {
            setIconDisabled(true);
            setTabsDisabled(true);
            document.getElementById("multiselectdiv").style.top = (document.getElementById("btn_AssignCleaningTasks").offsetTop + 35) + "px"
            document.getElementById("multiselectdiv").style.left = (document.getElementById("btn_AssignCleaningTasks").offsetLeft) + "px"
        } else {

            document.getElementById("multiselectdiv").style.top = (document.getElementById("showcleaningtasks" + id).offsetTop + 35) + "px"
            document.getElementById("multiselectdiv").style.left = (document.getElementById("showcleaningtasks" + id).offsetLeft) + "px"
        }

        if (document.getElementById("multiselectdiv").style.display === "none") {
            document.getElementById("multiselectdiv").style.display = "block";

            createcleaningtasksarray(id);


        } else {

            document.getElementById("multiselectdiv").style.display = "none";
        }

    }

    const changetaskcheckbox = (e,id) => {
        setLinkedtasks((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.checked
            }
        })
        setLinkedtasks((prev) => {
            console.log("changecheckbox")
           savelinkedTasks(id, prev).then((res) => {
               if (res.status !== 200) {
                   setErrorMessage(res.statusText)
               }else{
                setcleaningtaskstoassignarray(pre=>[]);
                createcleaningtasksarray(id) 
               }          
           })
       })
               
    }

    const [options, setOptions] = useState([]);

    const addCleaningtypefield = () => {
        setIconDisabled(true);
        setTabsDisabled(true);
        window.addEventListener("click", myfunction)
        const cleaningdiv = document.createElement("div");
        // //addname element to div
        const cleaningtypedescription = document.createElement("input");
        cleaningtypedescription.className = hotelcss.CTinput;
        cleaningtypedescription.type = "text";
        cleaningtypedescription.id = "cleaningtypedescription";
        cleaningdiv.appendChild(cleaningtypedescription);


        const Type = document.createElement("select");
        Type.id = "RoomType"

        cleaningdiv.appendChild(Type);

        for (const type of kamerTypes) {
            const option = document.createElement("option")
            option.innerText = type.Naam;
            option.value = type.id;
            Type.appendChild(option)

        }


        const cleaningtypeduration = document.createElement("input");
        cleaningtypeduration.className = hotelcss.CTinput;
        cleaningtypeduration.type = "number";
        cleaningtypeduration.id = "cleaningtypeduration";
        cleaningdiv.appendChild(cleaningtypeduration);

        cleaningdiv.className = hotelcss.CTypelinkVarientInput
        const cleaningtypearray = document.getElementById("cleaningtypearray")

        cleaningtypearray.insertBefore(cleaningdiv, cleaningtypearray.firstChild);
        const Icontasks = document.getElementById("cleaningservices").cloneNode(true);

        const addCleaningTasks = document.createElement("Button");
        addCleaningTasks.appendChild(Icontasks)
        addCleaningTasks.id = "btn_AssignCleaningTasks";
        addCleaningTasks.disabled = { tabsDisabled }
        addCleaningTasks.className = "AddButtonTable";

        cleaningdiv.appendChild(addCleaningTasks);
        // addCleaningTasks.onclick = () => handleassignCleaningTasks()

        const IconSave = document.getElementById("IconSave").cloneNode(true);
        const IconCancel = document.getElementById("IconCancel").cloneNode(true);

        const addbut = document.createElement("Button");
        addbut.appendChild(IconSave)
        addbut.className = "AddButtonTable";

        cleaningdiv.appendChild(addbut);
        addbut.onclick = () => handleaddCleaningType()
        cleaningdiv.id = "toberemoved"
        // // linkedcleaningtasks.focus()

        const cancelbutton = document.createElement("Button");
        cancelbutton.appendChild(IconCancel)
        cancelbutton.className = "AddButtonTable";
        cancelbutton.id = "tocancel"
        cleaningdiv.appendChild(cancelbutton);
        cancelbutton.onclick = () => handleCancelCleaningTypenewTask(id)

    }


    useEffect(() => {
        setcleaningtypearray([]);
        if (CleaningTypes.length > 0) {
            const types = CleaningTypes
            types.forEach((item, index) => {
                setcleaningtypearray(pre => [...pre,
                <div id={item.Id} className={index % 2 === 0 ? hotelcss.CTypelinkVarient1 : hotelcss.CTypelinkVarient2} >
                    <p>{item.description}</p>
                    <p>{item.Naam}</p>
                    <p>{item.duration}</p>
                    <p id={"showcleaningtasks" + item.Id}><MdCleaningServices  /></p>
                    <div className='crudbuttons'>
                        <MdEdit onClick={iconDisabled ? null : () => handleCleaningTypeEditClick(item.Id, item, index)} />
                        <MdDelete onClick={iconDisabled ? null : () => handlecleaningTypeDeleteClick(item)} />
                    </div>
                </div>
                ])
            })
        }

    }, [CleaningTypes, iconDisabled]);



    useEffect(() => {
        //console.log(CleaningTasks);
        if (cleaningTasks.length > 0) {
            //console.log("incleaningTasks array")
            //console.log(CleaningTasks)
            const types = cleaningTasks
            const array = []
            setcleaningtaskarray([]);
            //console.log(cleaningtaskarray)

            types.forEach((item, index) => {
                //console.log(item)

                setcleaningtaskarray(pre => [...pre,
                <div id={item.Id} className={index % 2 === 0 ? hotelcss.CTlinkVarient1 : hotelcss.CTlinkVarient2} >
                    <p>{item.taskDescription}</p>
                    <div className='crudbuttons'>
                        <MdEdit onClick={iconDisabled ? null : () => handleCleaningTaskEditClick(item.Id, item, index)} />
                        <MdDelete onClick={iconDisabled ? null : () => handleCleaningTaskDeleteClick(item)} />
                    </div>
                </div>
                ])
            })
        }

    }, [cleaningTasks, iconDisabled]);

    useEffect(() => {
        setkamertypesarray([]);
        if (kamerTypes.length > 0) {
            const types = kamerTypes
            types.forEach((item, index) => {
                //console.log(item)
                setkamertypesarray(pre => [...pre,
                <div id={item.id} className={index % 2 === 0 ? hotelcss.RTlinkVarient1 : hotelcss.RTlinkVarient2} >
                    <p>{item.Naam}</p>

                    <div className='crudbuttons'>
                        <MdEdit onClick={iconDisabled ? null : () => handleKamerTypeEditClick(item.id, item, index)} />
                        <MdDelete onClick={iconDisabled ? null : () => handleKamerTypeDeleteClick(item.Naam,item.id)} />
                    </div>
                </div>
                ])
            })
        }

    }, [kamerTypes, iconDisabled]);

    useEffect(() => {
        setkamersarray([]);
        if (kamers.length > 0) {            
            kamers.forEach((item, index) => {
                setkamersarray(pre => [...pre,
                <div id={item.kamerNummer} className={index % 2 === 0 ? hotelcss.RoomlinkVarient1 : hotelcss.RoomlinkVarient2} >
                    <p>{item.kamerNummer}</p>
                    <p>{item.Naam}</p>
                    <div className='crudbuttons'>
                        <MdEdit onClick={iconDisabled ? null : () => handleKamerEditClick(item.kamerNummer, item, index)} />
                        <MdDelete onClick={iconDisabled ? null : () => handleKamerDeleteClick(item.kamerNummer)} />
                    </div>
                </div>
                ])
            })
        }

    }, [kamers, iconDisabled]);


    const handleCleaningTaskEditClick = (id, item) => {

        setIconDisabled(true);
        setTabsDisabled(true)
        //console.log("inCleaningTaskedit")

        const cleaningtaskdiv = document.getElementById(id)
        cleaningtaskdiv.innerHTML = ""
        //addname element to div
        const type = document.createElement("input");
        type.id = "taskdescription" + id
        type.value = item.taskDescription
        type.className = hotelcss.CTinput;
        cleaningtaskdiv.appendChild(type);


        cleaningtaskdiv.className = hotelcss.CTlinkVarientInput

        const savebutton = document.createElement("Button");
        const IconSave = document.getElementById("IconSave").cloneNode(true);
        const IconCancel = document.getElementById("IconCancel").cloneNode(true);

        savebutton.appendChild(IconSave)
        savebutton.className = "AddButtonTable";
        cleaningtaskdiv.id = "toberemoved"
        cleaningtaskdiv.appendChild(savebutton);
        savebutton.onclick = () => handleEditCleaningTaskSave(id)

        const cancelbutton = document.createElement("Button");
        cancelbutton.appendChild(IconCancel)
        cancelbutton.className = "AddButtonTable";
        cancelbutton.id = "tocancel"
        cleaningtaskdiv.appendChild(cancelbutton);
        cancelbutton.onclick = () => handleCancelCleaningTaskSave(id)


    }

    const handleCleaningTypeEditClick = (id, item) => {

        setIconDisabled(true);
        setTabsDisabled(true)
        //console.log("in CleaningTypeedit")

        getRoomsTypes(hotel.Hotel_ID).then(res => {

            const cleaningtaskdiv = document.getElementById(id)
            cleaningtaskdiv.innerHTML = ""
            //addname element to div
            const type = document.createElement("input");
            type.id = "description" + id
            type.value = item.description
            type.className = hotelcss.CTinput;
            cleaningtaskdiv.appendChild(type);



            const Type = document.createElement("select");
            Type.id = "RoomType" + id

            cleaningtaskdiv.appendChild(Type);
            for (const type of kamerTypes) {
                const option = document.createElement("option")
                option.innerText = type.Naam;
                option.value = type.id;
                Type.appendChild(option)
            }

            const duration = document.createElement("input");
            duration.type = "number";
            duration.id = "duration" + id;
            duration.value = item.duration;
            duration.className = hotelcss.CTinput;
            cleaningtaskdiv.appendChild(duration);

            const cleaningserviceIcon = document.getElementById("cleaningservices").cloneNode(true);
            const IconSave = document.getElementById("IconSave").cloneNode(true);
            const IconCancel = document.getElementById("IconCancel").cloneNode(true);
            cleaningserviceIcon.style.display = "block";
            const addCleaningTasks = document.createElement("Button");
            addCleaningTasks.className = "AddButtonTable";
            addCleaningTasks.appendChild(cleaningserviceIcon)
            addCleaningTasks.id = "btn_AssignCleaningTasks";
            addCleaningTasks.className = "AddButtonTable";
            console.log(item)
            addCleaningTasks.onclick = () => handleassignCleaningTasks(item.Id)

            cleaningtaskdiv.appendChild(addCleaningTasks);
            cleaningtaskdiv.className = hotelcss.CTypelinkVarientInput

            const savebutton = document.createElement("Button");
            savebutton.appendChild(IconSave);
            savebutton.className = "AddButtonTable";
            cleaningtaskdiv.id = "toberemoved"
            cleaningtaskdiv.appendChild(savebutton);
            savebutton.onclick = () => handleEditCleaningTypeSave(id,linkedtasks)

            const cancelbutton = document.createElement("Button");
            cancelbutton.appendChild(IconCancel)
            cancelbutton.className = "AddButtonTable";
            cancelbutton.id = "tocancel"
            cleaningtaskdiv.appendChild(cancelbutton);
            cancelbutton.onclick = () => handleCancelCleaningTypeSave(id)
        })

    }

    const handleCancelRoomTypeSave = (id) => {
        setIconDisabled(false);
        setTabsDisabled(false);
        setkamertypesarray([]);
        refreshkamertypes();
    }

    const handleCancelCleaningTypeSave = (id) => {
        setIconDisabled(false);
        setTabsDisabled(false);
        document.getElementById("multiselectdiv").style.display = "none";
        setcleaningtypearray([]);
        refreshcleaningtypes();
    }

    const handleCancelCleaningTaskSave = (id) => {
        setIconDisabled(false);
        setTabsDisabled(false);

        setcleaningtaskarray([]);
        refreshcleaningtasks();
    }

    const handleCancelKamerSave = (id) => {
        setIconDisabled(false);
        setTabsDisabled(false);
        setkamersarray([]);
        refreshkamers();
    }

    const handleCancelCleaningTypenewTask = (id) => {
        setIconDisabled(false);
        setTabsDisabled(false);
        document.getElementById("multiselectdiv").style.display = "none";
        document.getElementById("cleaningtypearray").removeChild(document.getElementById("toberemoved"));
        //setcleaningtypearray([]);
        // refreshcleaningtypes();
    }

    const handleCancelCleaningTasknewTask = (id) => {
        setIconDisabled(false);
        setTabsDisabled(false);
        document.getElementById("cleaningtaskarray").removeChild(document.getElementById("toberemoved"))
        setcleaningtaskarray([]);
        refreshcleaningtasks();
    }

    const handleCancelRoomTypenewTask = (id) => {
        setIconDisabled(false);
        setTabsDisabled(false);
        document.getElementById("kamerstypeaaray").removeChild(document.getElementById("toberemoved"))
        setcleaningtaskarray([]);
        refreshcleaningtasks();
    }

    const handleCancelRoomnewTask = (id) => {
        setIconDisabled(false);
        setTabsDisabled(false);
        document.getElementById("kamersarray").removeChild(document.getElementById("inputdiv"))
        setkamersarray([]);
        refreshkamers();
    }

    const handleEditCleaningTypeSave = (id,tasks) => {

        console.log("tasks",tasks)
        document.getElementById("multiselectdiv").style.display = "none";

        setIconDisabled(false);
        setTabsDisabled(false)
        //console.log("handleEditCleaningTypeSave")
        const description = document.getElementById("description" + id).value;
        const duration = document.getElementById("duration" + id).value;
        const roomtype_id = document.getElementById("RoomType" + id).value;

        const response = editCleaningType(id, description, duration, roomtype_id);
        response.then(res => {
            if (res.status === 200) {
                console.log(id, linkedtasks)
                savetasks(id)
            } else {
                setErrorMessage(res.statusText)
            }
        })

    }

   function savetasks(id){
    
    setLinkedtasks((prev) => {
        console.log(prev)
        savelinkedTasks(id, prev).then((res) => {
            console.log(res.status)
            if (res.status === 200) {
                setcleaningtypearray([])
                refreshcleaningtypes();

            } else {
                setErrorMessage(res.statusText)
            }                
        })
        
    })
        
    }

    async function savetaskschange(id){

    }


    const handleEditCleaningTaskSave = (id) => {
        setIconDisabled(false);
        setTabsDisabled(false)
        const taskDescription = document.getElementById("taskdescription" + id).value;
        const taskDuration = 0;
        //console.log(taskDescription, id, durationvalue)
        //editCleaningTaskid, taskDescription, taskDuration
        const response = editCleaningTask(id, taskDescription, taskDuration);
        response.then(res => {
            if (res.status === 200) {
                setcleaningtaskarray([])
                refreshcleaningtasks();

            } else {
                setErrorMessage(res.statusText)
            }
        })

    }

    const handleKamerEditClick = async (id) => {

        setIconDisabled(true);
        setTabsDisabled(true)
        getRoomsTypes(hotel.Hotel_ID).then(res => {

            if (res.status === 200) {
                setKamerTypes(res.items)
                const kamerdiv = document.getElementById(id)
                kamerdiv.innerHTML = ""
                //addname element to div
                const kamernummer = document.createElement("p");
                kamernummer.id = "kamernummerinput"
                kamernummer.innerText = id
                kamerdiv.appendChild(kamernummer);

                const Type = document.createElement("select");
                Type.onchange = (e) => setaddkamer({ ...addkamer, Type: e.target.value })
                kamerdiv.appendChild(Type);
                Type.id = "kamertypeid"

                kamerdiv.className = hotelcss.linkVarientInput



                for (const type of res.items) {
                    const option = document.createElement("option")
                    option.innerText = type.Naam;
                    option.value = type.id;
                    Type.appendChild(option)


                }

                const savebutton = document.createElement("Button");

                const IconSave = document.getElementById("IconSave").cloneNode(true);
                const IconCancel = document.getElementById("IconCancel").cloneNode(true);

                savebutton.appendChild(IconSave)
                savebutton.className = "AddButtonTable";
                kamerdiv.id = "inputdiv"

                kamerdiv.appendChild(savebutton);
                savebutton.onclick = () => handleEditkamerSave(id)
                const cancelbutton = document.createElement("Button");
                cancelbutton.appendChild(IconCancel)
                cancelbutton.className = "AddButtonTable";
                cancelbutton.id = "tocancel"
                kamerdiv.appendChild(cancelbutton);
                cancelbutton.onclick = () => handleCancelKamerSave(id)

            } else {
                setErrorMessage("failed to retrieve rooms")
            }

        })







    }

    const handleEditkamerSave = (id) => {
        setIconDisabled(false);
        setTabsDisabled(false)
        const type = document.getElementById("kamertypeid").value
        //console.log(type, id)

        const response = editkamer(id, type, hotel.Hotel_ID);
        response.then(res => {
            if (res.status === 200) {
                res.json().then(body => {
                    setkamersarray(pre => [])
                    setKamers(body);

                })
            } else {
                setErrorMessage(res.statusText)
            }
        })


    }

    const handleKamerDeleteClick = (id) => {
        const options = {
            title: 'Delete?',
            message: 'Wil jij kamer "' + id + '" deleten?',

            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        const response = deleteRoom(id, hotel.Hotel_ID)
                        response.then(res => {
                            res.json().then(res => {
                                console.log(res.items)
                                setKamers(res.items)
                            }
                            )

                        })

                    }
                },
                {
                    label: 'No',
                }
            ],

            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
            overlayClassName: "overlay-custom-class-name"
        };

        confirmAlert(options)

    }

    const handleKamerTypeEditClick = async (id, item) => {

        setIconDisabled(true);
        setTabsDisabled(true);

        //await refreshkamertypes();
        //console.log("inkameredit")


        const kamerdiv = document.getElementById(id)
        kamerdiv.innerHTML = ""
        //addname element to div
        const type = document.createElement("input");
        type.id = "kamername" + id
        type.value = item.Naam
        kamerdiv.appendChild(type);


        kamerdiv.className = hotelcss.RTlinkVarientInput

        const savebutton = document.createElement("Button");

        const IconSave = document.getElementById("IconSave").cloneNode(true);
        const IconCancel = document.getElementById("IconCancel").cloneNode(true);


        savebutton.appendChild(IconSave)
        savebutton.className = "AddButtonTable";
        kamerdiv.id = "toberemoved"
        kamerdiv.appendChild(savebutton);
        savebutton.onclick = () => handleEditkamerTypeSave(id)

        const cancelbutton = document.createElement("Button");
        cancelbutton.appendChild(IconCancel)
        cancelbutton.className = "AddButtonTable";
        cancelbutton.id = "tocancel"
        kamerdiv.appendChild(cancelbutton);
        cancelbutton.onclick = () => handleCancelRoomTypeSave(id)


    }

    const handleEditkamerTypeSave = (id) => {
        setIconDisabled(false);
        setTabsDisabled(false)
        const name = document.getElementById("kamername" + id).value
        //console.log(name, id)

        const response = editkamerType(id, name, hotel.Hotel_ID);
        response.then(res => {
            if (res.status === 200) {
                res.json().then(body => {
                    setkamertypesarray(pre => [])
                    setKamerTypes(body);
                    refreshkamers()

                })
            } else {
                setErrorMessage(res.statusText)
            }
        })

    }
    const changecheckbox = (e) => {
        setHotel((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.checked
            }

        })

        setToUpdate((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.checked
            }

        })

    }



    function myfunction(e) {
        try {
            const container = document.getElementById('btn_AssignCleaningTasks');
            const container2 = document.getElementById("multiselectdiv")
            if (!container.contains(e.target) && !container2.contains(e.target)) {
                document.getElementById("multiselectdiv").style.display = "none"
            }
            //console.log(document.getElementById("multiselectdiv").style.display)
        } catch (e) { }
    }

    const handleKamerTypeDeleteClick = (naam,id) => {
        const options = {
            title: 'Delete?',
            message: 'Wil jij kamerType "' + naam + '" deleten?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        deleteRoomtype(id).then((res) => {
                            if (res.status === 200) {
                                res.json().then(body => {
                                    console.log(body)
                                    setKamerTypes(body)
                                })
                            } else {
                                setErrorMessage(res.statusText)
                            }
                        })
                        
                    }
                },
                {
                    label: 'No',
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
            overlayClassName: "overlay-custom-class-name"
        };

        confirmAlert(options)

    }

    const handlecleaningTypeDeleteClick = (item) => {
        //console.log(item)
        const options = {
            title: 'Delete?',
            message: 'Wil jij cleaning type "' + item.description + '" deleten?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        const response = deleteCleaningType(item.Id)
                        response.then(res => {
                            res.json().then(res => {
                                console.log(res.items)
                                setCleaningTypes(res.items)
                            }
                            )

                        })

                    }
                },
                {
                    label: 'No',
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
            overlayClassName: "overlay-custom-class-name"
        };

        confirmAlert(options)

    }

    const handleCleaningTaskDeleteClick = (item) => {
        const options = {
            title: 'Delete?',
            message: 'Wil jij cleaning task "' + item.taskDescription + '" deleten?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        const response = deleteCleaningTask(item.Id)
                        response.then(res => {
                            res.json().then(res => {
                                setCleaningTasks(res.items)
                            }
                            )

                        })

                    }
                },
                {
                    label: 'No',
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
            overlayClassName: "overlay-custom-class-name"
        };

        confirmAlert(options)

    }

    const CloseForm = () => {
        document.getElementById('formcontent').style.display = 'none';
        document.getElementById('closeformchevronDown').style.display = 'block';
        document.getElementById('closeformchevronUp').style.display = 'none';
    }
    const OpenForm = () => {
        document.getElementById('formcontent').style.display = 'block';
        document.getElementById('closeformchevronDown').style.display = 'none';
        document.getElementById('closeformchevronUp').style.display = 'block';
    }

    return (
        <>

            <div id="main">
                <header>
                    <div id="errordiv" className='error'></div>
                </header>
                <div id="return" className="form">
                    <Button type="button" id="cancelButton" class="Savebutton btn btn-primary margin-right" onClick={() => navigate("/hotels")} >Return to list</Button>
                </div>


                <form className="form" onSubmit={handleSave} autocomplete="off">
                    <div style={{ display: "none" }} id="MyIcons">
                        <MdCleaningServices id="cleaningservices" />
                        <MdOutlineCheck id="IconSave" />
                        <MdOutlineCancel id="IconCancel" /></div>

                    <div className='formCollapse'>
                        <label className='formtitle'>
                            Hotel Details '{hotel.Name}'
                        </label>
                        <HiOutlineChevronDown onClick={OpenForm} id='closeformchevronDown' style={{ display: "none" }} />
                        <HiOutlineChevronUp onClick={CloseForm} id='closeformchevronUp' />
                    </div>
                    <div id="formcontent">
                        <div className="formchild" id='FPactive'>
                            <label className='labelhotel'>
                                Actief:
                            </label>
                            <input id="hotelActive" type='CheckBox' className="checkbox " value={hotel.active} name="active" onChange={changecheckbox} checked={hotel.active} disabled></input>
                        </div>
                        <div className="formchild">
                            <label className='labelhotel'>
                                Name:
                            </label>
                            <input id="hotelnaam" name="Name" required className="normalinput" value={hotel.Name} onChange={handleitemchange} type='text' disabled ></input>
                            <label className='labelhotel'>
                                Department number:
                            </label>
                            <input id="afdeling" name="nr_afd" required className="normalinput" value={hotel.nr_afd} onChange={handleitemchange} type='number' disabled></input>
                        </div>
                        <div className="formchild">
                            <label className='labelhotel'>
                                BTW:
                            </label>
                            <input id="btw" className="doublegrid" required type='text' name="btw_nummer" value={hotel.btw_nummer} onChange={handleitemchange} disabled></input>
                        </div>

                        <div className="formchild">
                            <label className='labelhotel'>
                                Street name:
                            </label>
                            <input id="straatnaam" type='text' required className="doublegrid" name="straatnaam" value={hotel.straatnaam} onChange={handleitemchange} disabled ></input>
                            <label className='labelhotel'>
                                House number:
                            </label>
                            <input id="huisnummer" type='number' required className="normalinput" name="huisnummer" value={hotel.huisnummer} onChange={handleitemchange} disabled></input>
                            <label className='labelhotel'>
                                Bus:
                            </label>
                            <input id="bus" type='text' className="normalinput" name="bus" value={hotel.bus} onChange={handleitemchange} disabled></input>
                            <label className='labelhotel'>
                                Zipcode:
                            </label>
                            <input id="zipcode" type='number' required className="normalinput" name="zipcode" value={hotel.zipcode} onChange={handleitemchange} disabled></input>
                            <label className='labelhotel'>
                                City:
                            </label>
                            <input id="gemeente" type='text' required className="normalinput" name="gemeente" value={hotel.gemeente} onChange={handleitemchange} disabled></input>
                            <label className='labelhotel'>
                                Country:
                            </label>
                            <input id="land" type='text' required className="normalinput" name="land" value={hotel.land} onChange={handleitemchange} disabled></input>
                        </div>
                        <div className="formchild">
                            <label className='labelhotel'>
                                GPS location:
                            </label>
                            <input id="gpslocatie" type='text' className="doublegrid" name="gps_locatie" value={hotel.gps_locatie} onChange={handleitemchange} disabled></input>
                        </div>
                        <div className="formchild">
                            <label className='labelhotel'>
                                Contractual ref.:
                            </label>
                            <input id="contractrelationNumber" type='text' className="doublegrid" name="contractrelationNumber" value={hotel.contractrelationNumber} onChange={handleitemchange} disabled></input>
                        </div>
                        <div className="formchild">
                            <label className='labelhotel'>
                                Contact:
                            </label>
                            <input id="contactperson" type='text' className="doublegrid" name="contactperson" value={hotel.contactperson} onChange={handleitemchange} disabled></input>
                            <label className='labelhotel'>
                                Contact number:
                            </label>
                            <input id="contactnumber" type='tel' className="doublegrid" name="contactnumber" value={hotel.contactnumber} onChange={handleitemchange} disabled></input>
                        </div>
                        <div id="uploaddiv" className="formchild">
                            <label className='labelhotel'>
                                Upload Contract:
                            </label>
                            <input id="uploadContract" className="normalinput" type='file' accept='.pdf' onChange={(e) => setContract({ value: e.target.files[0] })}></input>
                        </div>
                        <div id="contractdiv" className="formchild gridform3ColsHotel">
                            <label className='labelhotel'>
                                Contract:
                            </label>
                            <input id="contract" name="contract" value={hotel.Contract} disabled></input>
                            <Button type="button" id="viewcontractButton" onClick={openContract} className="openContract">View</Button>
                        </div>
                        <div className="formchild">
                            <label className='labelhotel'>
                                Note:
                            </label>
                            <textarea id="Note" type='textarea' className="doublegrid" name="note" value={hotel.note} onChange={handleitemchange} disabled></textarea>
                        </div>
                        <div id="editDiv" className="formchildButton">
                            <Button id="editButton" className='' onClick={handleEdit} disabled={tabsDisabled}>Edit</Button>
                        </div>
                        <div id="saveDiv" className="two_Buttons">
                            <Button id="saveButton" type="submit" className='Savebutton'>Save</Button>
                            <Button id="cancelButton" className='Savebutton' onClick={handleCancel}>Cancel</Button>
                        </div>
                    </div>
                </form>

                <div className='form'>
                    <Tabs className="HotelTabs" onSelect={(index) => kamerstab(index)}>
                        <TabList >
                            <Tab disabled={tabsDisabled}>Kamers</Tab>
                            <Tab disabled={tabsDisabled}>Kamer types</Tab>
                            <Tab disabled={tabsDisabled}>Cleaning types</Tab>
                            <Tab disabled={tabsDisabled}>Cleaning tasks</Tab>
                        </TabList>
                        <TabPanel>
                            <div className='tabpanel'>
                                <Button id="addkamerButton" className='Savebutton' onClick={() => addKamerfield()} disabled={tabsDisabled}>Add room</Button>
                                <div className={hotelcss.roomtableheader}><p>Kamer nummer</p><p>Type</p></div>
                                <div id='roomslist' className='list'>
                                    <div id="kamersarray" >{kamersarray}</div>

                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel >
                            <div className='tabpanel'>
                                <Button id="addkamertypes" className='Savebutton' onClick={addKamertypefield} disabled={tabsDisabled}>Add room type</Button>
                                <div className={hotelcss.roomtypestableheader}><p>Type</p></div>
                                <div id='typeslist' className='list'>
                                    <div id="kamerstypeaaray" >
                                        {kamertypesarray}
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel >
                            <div className='tabpanel'>
                                <Button id="saveButton" className='Savebutton' onClick={addCleaningtypefield} disabled={tabsDisabled}>Add Cleaning type</Button>
                                <div className={hotelcss.cleaningtypetableheader}><p>Description</p><p>Room type</p><p>Duration</p><p>Tasks</p></div>
                                <div id='cleaningtypeslist' className='list'>

                                    <div id="multiselectdiv" style={{ display: "none" }} className={hotelcss.cleanintypesdiv}>
                                        {cleaningtaskstoassignarray}
                                    </div>

                                    <div id="cleaningtypearray" >
                                        {cleaningtypearray}
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel >
                            <div className='tabpanel'>
                                <Button id="saveButton" className='Savebutton' onClick={addCleaningtaskfield} disabled={tabsDisabled}>Add Cleaning task</Button>
                                <div className={hotelcss.cleaningtasktableheader}><p>Description</p></div>
                                <div id='cleaningtaskslist' className='list'>

                                    <div id="cleaningtaskarray" >
                                        {cleaningtaskarray}
                                    </div>

                                </div>
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </>
    )
}

export { Createhotel, Viewhotels, HotelView }