
import { useNavigate } from 'react-router-dom'
import {  retrieveCheckinData } from '../dbconnector/connector'
import { getCookie } from '../dbconnector/checktoken'
import './css/checkinpage.css';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { useState } from 'react';


const setErrorMessage = (message) => {
    const errormessage = document.getElementById("errorMessage");
    errormessage.innerHTML = ""
    errormessage.innerText = message
    errormessage.className = "showError"
}



const hideErrorMessage = () => {
    const errormessage = document.getElementById("errorMessage");
    errormessage.innerHTML = ""
    errormessage.className = "hideError"
}



const Constructcheckinpage = () => {
    const [zoekbalk, setZoekbalk] = useState('');

    const navigate = useNavigate();
    let checkinlist = "";


    retrieveCheckinData(Date.now()).then((result) => {
        if (result.errormessage) {
            setErrorMessage(result.errormessage)
        } else if (!checkinlist.count) {
            checkinlist = result;
            createcheckinlist(checkinlist.checkins, navigate);
        }

    })


    const filterlist = async (navigate) => {
        try{  console.log("in filter")
            let list = checkinlist.checkins;
            const namevalue = zoekbalk;
            const date = document.getElementById("dateinput").value;
            const workpausefilter = document.getElementById("selectionlist");
            retrieveCheckinData(date).then((result) => {
                console.log(result);
                    list = result.checkins;
                    if (namevalue !== "") {
                        list = list.filter(element => (element.voornaam + " " + element.achternaam).toLowerCase().includes(namevalue.toLowerCase()))
                    }
                    if (workpausefilter !== "") {
                        list = list.filter(element => (element.type.includes(workpausefilter.value)))
                    }
                    createcheckinlist(list, navigate)
    
    
        })

        }catch(e){setErrorMessage("failed to filter")}
      
    }

    const createcheckinlist = (list, navigate) => {
        hideErrorMessage();


        let divlist = document.getElementById("list")
        //id divlist does not exist we need to create filters and create divlist otherwise we need to empty the divlist
        //this so that the data is not dupplicated
        if (divlist === null) {

            const upperdiv = document.getElementById("main");

            divlist = document.createElement("div");
            divlist.id = "list";

            upperdiv.appendChild(divlist);
        } else {
            divlist.innerHTML = "";
        }

        /* #region  creating headings */
        const employediv = document.createElement("div")
        divlist.appendChild(employediv)


        const EMP_ID_div = document.createElement("p")
        EMP_ID_div.innerText = "ID"
        employediv.appendChild(EMP_ID_div)

        const name = document.createElement("p")
        employediv.appendChild(name)
        name.innerText = "Fullname"

        const dateElement = document.createElement("p")
        dateElement.innerText = "Date"
        employediv.appendChild(dateElement);

        const timeElement = document.createElement("p");
        timeElement.innerText = " Time in"
        employediv.appendChild(timeElement);
        employediv.className = "tableheader"
        dateElement.className = "numberalign"

        const timeOutElement = document.createElement("p");
        timeOutElement.innerText = " Time out"
        employediv.appendChild(timeOutElement);
        employediv.className = "tableheader"
        dateElement.className = "numberalign"

        const Type = document.createElement("p");
        Type.innerText = " Type"
        employediv.appendChild(Type);
        employediv.className = "tableheader gridCheckInList"


        /* #endregion */
        //create a list item for every element in the received array
        list.forEach((element, index) => {
            //create wrapper employeediv
            const employediv = document.createElement("div")
            divlist.appendChild(employediv)


            const EMP_ID_div = document.createElement("p")
            EMP_ID_div.innerText = element.nr_wkn
            employediv.appendChild(EMP_ID_div)

            const name = document.createElement("p")
            employediv.appendChild(name)
            name.innerText = element.voornaam + " " + element.achternaam


            const dateTime = new Date(element.loginDateTime)

            const dateElement = document.createElement("p")
            dateElement.innerText = dateTime.toLocaleDateString();
            employediv.appendChild(dateElement);

            const timeElement = document.createElement("p");
            timeElement.innerText = dateTime.toLocaleTimeString();
            employediv.appendChild(timeElement);
            dateElement.className = "numberalign"

            let dateTimeIn
            if (element.checkoutTime === null) {
                dateTimeIn = "NA"
            } else {
                dateTimeIn = new Date(element.checkoutTime).toLocaleTimeString();
            }
            
            const timeOutElement = document.createElement("p");
            timeOutElement.innerText = dateTimeIn
            employediv.appendChild(timeOutElement);
            dateElement.className = "numberalign"
            const Type = document.createElement("p");
            Type.innerText = element.type.toUpperCase()
            employediv.appendChild(Type);
            employediv.className = "tableheader"

            //dynamicaly add classname
            if (index % 2 === 0) {
                employediv.className = "linkVarient1 gridCheckInList"
            } else {
                employediv.className = "linkVarient2 gridCheckInList"
            }
            // employediv.addEventListener('click', function (e) {
            //     navigate("/checkin/" + element.ID);
            // });

        });

    }

    //onChange={onChange} value={value}

    return (

        <div id="main">
            <div id="errorMessage"></div>
            <div id="filters" className="form" >
                <label className='formtitleContentBox'>filter instellingen</label>
                <br></br>
                <div className="contentbox">
                   
                    <div className="formElement">
                        <label className='labelcolor'>
                            day:
                        </label>
                        <input id="dateinput" type='Date'></input>
                    </div>
                    <div className="formElement">
                        <label className='labelcolor'>
                            Werknemer:
                        </label>
                        <input id="Zoekbalk" type="text" placeholder="Search" value={zoekbalk} onChange={e => setZoekbalk(e.target.value)} className="SearchButton"></input>
                    </div>
                    <div className="formElement">
                        <label className='labelcolor'>
                            Type:
                        </label>
                        <select id='selectionlist' className="filterTypeSelect">
                            <option value=""></option>
                            <option value="work">work</option>
                            <option value="pause">pause</option>
                        </select>
                    </div>
                    <div className="formElement">
                        <label className='labelcolor' />
                        <button className='buttoncontainer filterButton' type='button' id="filterbutton" onClick={() => filterlist(navigate)}>filter</button>
                    </div>
                    <br></br>
                </div>
                <br></br>
                <div id="list" className="list">

                </div>
            </div>

        </div>
    )

}


export { Constructcheckinpage }