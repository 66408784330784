
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { checktoken } from '../dbconnector/checktoken';
import { useEffect } from 'react';
import { setErrorMessage } from "../tools/errorhandling";
import Languageselector from '../tools/language-selector'
import Cookies from 'universal-cookie';
import { getMyNotifications, getMyNotificationCount } from '../dbconnector/connector';
import { MdNotifications } from "react-icons/md";
import { VscFile } from "react-icons/vsc";
import { MdOutlineCleaningServices } from "react-icons/md";
import { IoMdCloseCircle } from "react-icons/io";
import { useState } from 'react'

const logout = () => {
  const cookies = new Cookies();
  // document.cookie = "logintoken=";
  cookies.set('logintoken', '', { path: '/' });

}

const Navigatie = (props) => {
  const [notifications, setNotifications] = useState([]);
  const [notificationArray, setnotificationArray] = useState([]);
  const [notificationCount, setnotificationCount] = useState(0);

  const t = props.translation;
  const loggedin = props.loggedIn

  const getnotificationcount = () => {
    const count = getMyNotificationCount();
    count.then(res => {
      if (res.status === 200) {
        res.json().then(body => {
          setnotificationCount(body[0]['count(*)'])
        })
      }
    })
  }



  useEffect(() => {
    setInterval(getnotificationcount, 60000);
    document.getElementById("Notifications").style.display = "none";
   // document.getElementById("checkoutlink").style.display="none";
  }, []);

  const iconTranslation = {
    Mededeling: (<VscFile className='NotificationIcon' />),
    Work_notification: (<MdOutlineCleaningServices className='NotificationIcon' />),
    Info: (<VscFile className='NotificationIcon' />)
  }

  useEffect(() => {
    
    setnotificationArray([])
    if (notifications.length > 0) {
      notifications.forEach(notification => {
        setnotificationArray(pre => [...pre,

        <div className="notificationcontainer" id={notification.id}>
          <div className="push__wrapper">
            <div className="push__box">
              <div className="push__content">
                <div className="push__top">
                  <div className="col">
                    <div className="push__imgwrap">
                      {iconTranslation[notification.message_type]}
                    </div>
                    <span className="push__title">{notification.title}</span>

                  </div> <IoMdCloseCircle className='CloseNotification' onClick={() => closenotification(notification.id)} />
                </div>
                <div className="push__main">
                  <div className="push__person">{notification.creator}</div>
                  <p className='notificationMessage'>{notification.message}</p>

                </div>
              </div>
            </div>
          </div>
        </div>

        ])

      });

    } else {

    }


  }, [notifications]);

  const CheckIn = async () => {
    //alert("CheckIn");
    document.getElementById("checkinlink").style.display="none";
    document.getElementById("checkoutlink").style.display="block";
  }
  const CheckOut = async () => {
    //alert("CheckOut");
    document.getElementById("checkinlink").style.display="block";
    document.getElementById("checkoutlink").style.display="none";
  }
  const getNotifications = () => {
    const response = getMyNotifications();
    response.then(res => {
      if (res.status === 200) {

        res.json().then(body => {
          setNotifications(pre => [...body])
        })

      } else {
        setErrorMessage(res.statusText)
      }
    });

  }

  const isGenerallyVisible = (element) => {
    const style = document.getElementById("Notifications").style
    return style.display !== 'none' && style.visibility !== 'hidden' && style.opacity !== '0'
  }

  const closenotificationwindow = () => {
    const notificationnode = document.getElementById("Notifications")
    if (notificationnode.style.display === "block") {
      notificationnode.style.display = "none";
    }
  }

  const loadnotification = () => {

    document.getElementById("main").onclick = closenotificationwindow


    getnotificationcount();
    if (isGenerallyVisible(document.getElementById("Notifications"))) {
      document.getElementById("Notifications").style.display = "none";
    } else {
      document.getElementById("Notifications").style.display = "block";
      getNotifications();
    }

    //document.getElementById("Notifications").style.top = (document.getElementById("NotificationIconMenu").offsetTop -10) + "px"
    //document.getElementById("Notifications").style.left = (document.getElementById("NotificationIconMenu").offsetLeft-250) + "px"

  }
  const closenotification = (id) => {

    document.getElementById(id).style.display = "none";
    loadnotification();
    // setnotificationCount(pre=>pre-1)
  }

  return (

    <header>
      {getnotificationcount()}
      {(props.rights === 2) ? (
        <Navbar expand="lg" fixed='top' className='TopNavigatie' >
          <Navbar.Brand href="/home">
            <img className='Brandlogo'
              src="/img/Logo_stars.png"
              alt="SHC logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" >
            <Nav className="me-auto">
              <NavDropdown title="HOTEL MANAGEMENT" id="basic-nav-dropdown" className='menucontainer' >
                <NavDropdown.Item href="\hotels" className='NavDropdownItem'>Hotels</NavDropdown.Item>
                {/* <NavDropdown.Item href="\AssignCleaningTasks" className='NavDropdownItem'>Build Schedule</NavDropdown.Item> */}
              </NavDropdown>
              <NavDropdown title="FACTURATIE" id="basic-nav-dropdown" className='menucontainer'>
                <NavDropdown.Item className='NavDropdownItem' href="#action/3.1">Menu 1</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="EMPLOYEES" id="basic-nav-dropdown" className='menucontainer'>
                <NavDropdown.Item className='NavDropdownItem' href="/employees">All Employees</NavDropdown.Item>
                <NavDropdown.Item className='NavDropdownItem' href="/checkins">Employee Check-ins</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item className='NavDropdownItem' href="/daystats">Daily Employee statistics</NavDropdown.Item>
                <NavDropdown.Item className='NavDropdownItem' href="/ciaw">CIAW Table</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="MYSHC" id="basic-nav-dropdown" className='menucontainer'>
                <NavDropdown.Item className='NavDropdownItem' href="/mydata">My Personal Data</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item className='menucontainer' id="checkinlink" onClick={CheckIn}>Checkin-at-work</NavDropdown.Item>
                <NavDropdown.Item className='menucontainer nodisplay' id="checkoutlink" onClick={CheckOut}>Checkout-work</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link onClick={() => logout()} href='/' className='menucontainer'>LOG-OUT</Nav.Link>
              <div id="NotificationIconMenu" className='NotificationIconMenu' onClick={loadnotification}>
                <div id='nr_Ofnotifications' className='NotificationQuantity'>{notificationCount}</div>
                <MdNotifications className='NotificationMenu' />
              </div>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      ) : props.rights === 1 ? (
        <div className='TopNavigatie'>
          <div className='Brandlogo'>
            <a href="/">
              <img
                src="/img/logo_phone_SHC.svg"
                alt="SHC logo"
                className='BrandHeight'
              />
            </a></div>
          <div className='top_logos' >
            <img
              id="IconCleaning"
              src="/img/ico_CleaningTasks.svg"

              className="Icon_class"
              alt="Cleaning tasks"
            />
            <img
              id="IconMyData"
              src="/img/ico_MyData.svg"

              className="Icon_class"
              alt="My Data"
            />
            <img
              id="IconMyHoliday"
              src="/img/ico_Holiday.svg"

              className="Icon_class"
              alt="My Holiday"
            />
            <img
              id="IconMyContract"
              src="/img/ico_MyContract.svg"

              className="Icon_class"
              alt="My Contract"
            />
          </div>


          <div className='language_logo' >
            <Languageselector />
          </div>
          <div className='loggout_logo' >
            <a onClick={() => logout()} href='/'>
              <img
                id="IconCleaning"
                src="/img/ico_loggout.svg"
                height={30}
                className="Icon_class"
                alt="log-out"
              /></a>
          </div>
          {/* <div className='NotificationIconMenu' onClick={loadnotification}>
            <div id='nr_Ofnotifications' className='NotificationQuantity'>{notificationCount}</div>
            <MdNotifications className='NotificationMenu' />
          </div> */}
        </div>

      )
        : props.rights === 3 ? (
          <Navbar expand="lg" fixed='top' className='TopNavigatie' >
            <Navbar.Brand href="/home">
              <img className='Brandlogo'
                src="/img/Logo_stars.png"
                alt="SHC logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" >
              <Nav className="me-auto">
                <NavDropdown title="CLEANING MANAGEMENT" id="basic-nav-dropdown" className='menucontainer' >
                  <NavDropdown.Item href="\AssignCleaningTasks" className='NavDropdownItem'>Build Day Schedule</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="REPORTS" id="basic-nav-dropdown" className='menucontainer'>
                  <NavDropdown.Item className='NavDropdownItem' href="/checkins">Employee Check-ins Report</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item className='NavDropdownItem' href="/daystats">Daily Employee statistics</NavDropdown.Item>
                  <NavDropdown.Item className='NavDropdownItem' href="#action/3.1">Daily Report</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="MYSHC" id="basic-nav-dropdown" className='menucontainer'>
                  <NavDropdown.Item className='NavDropdownItem' href="/mydata">My Personal Data</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item className='menucontainer' id="checkinlink" onClick={CheckIn}>Checkin-at-work</NavDropdown.Item>
                  <NavDropdown.Item className='menucontainer nodisplay' id="checkoutlink" onClick={CheckOut}>Checkout-work</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link onClick={() => logout()} href='/' className='menucontainer'>LOG-OUT</Nav.Link>
                <div id="NotificationIconMenu" className='NotificationIconMenu' onClick={loadnotification}>
                  <div id='nr_Ofnotifications' className='NotificationQuantity'>{notificationCount}</div>
                  <MdNotifications className='NotificationMenu' />
                </div>
              </Nav>
            </Navbar.Collapse>
          </Navbar>

        ) : null
      }

      <div className='notifications' id="Notifications" onClick={loadnotification}>
        {notificationArray}
      </div>
    </header>

  )
}
//

export default Navigatie;