
import React from "react"
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react';
import { setErrorMessage } from "../tools/errorhandling";

import './css/tabs.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Button from 'react-bootstrap/Button';
import { MdEdit, MdCheck } from "react-icons/md";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';



const extraFunctie = () =>{
    
}



const MyholidayRequest = (props) => {


    const t = props.translation

    //t("CheckIn")
    // create navigation object if needed
    const navigate = useNavigate();



    //dop something after loading page
    useEffect(() => {

        try {
            // call on backend here if so that app does not crash and spews error code


            //simulatie van backendcall zodat code werkt
            const data = async () => {

            };

            //wachten op response van server. code binnen de brackets uitvoeren wanner de response er is (=callback function)
            data.then(response => {
                if (response === 200) {
                    //dosomething with data
                    const contentdiv = document.getElementById("content")
                } else {
                    throw new Error(response.status + ":" + response.message)
                }
            })
        } catch (error) {
            setErrorMessage(error.message)
        }


    }, []);

    return (
        <>
            <div id="main" className="main">
            <header><div id="errordiv" className='error'></div><div className="checkinTime"><div id="timelabel" ></div><div id="time"></div></div></header>
                <div id="content">
                    <br></br>
                <Accordion>
                    <Accordion.Item eventKey="0" className="CleaningTask">
                        <Accordion.Header>New holiday request</Accordion.Header>
                        <Accordion.Body>

                            <div id="startdate" >
                                <label>Start date:</label><input type="date" id="start" name="trip-start" />
                            </div>
                            <div id="endate" >
                            <label>End date:</label><input type="date" id="end" name="trip-end"/>

                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                </div>
            </div>
        </>
    )

}

export default MyholidayRequest