import React from "react"
import { useNavigate } from 'react-router-dom'

import { useEffect } from 'react';
import { setErrorMessage } from "../tools/errorhandling";
import { myData } from "../dbconnector/connector";
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}





const MyData = (props) => {
    const t = props.translation

    const navigate = useNavigate();
    // const [list, setMyData] = useState();
    let employeeValues;

    const data = myData();

    //pappa heeft dit toegevoegd
    const [user, setUser] = useState({});
// tot hier


    useEffect(() => {
        data.then(res => {
            try {
                document.getElementById("IconCleaning").style.display = "none";
                document.getElementById("IconMyData").style.display = "visible";
                document.getElementById("IconMyHoliday").style.display = "none";
                document.getElementById("IconMyContract").style.display = "none";
                console.log(res)
                employeeValues = res;

                //constructUserProfile(res);
                document.getElementById("editData").addEventListener("click", makeprofileEdit);

            } catch (e) {
                setErrorMessage("Error While retrieving user data")
            }

        })



    }, []);

    // pappa heeft onderstaande 2 constants toegevoegd zodat de pagina kon renderen
    const onSubmit = ()=>{

        
    };
    const changevalue= (e)=>{

        setUser((prev) => {
            return {
                // ...prev,
                // [e.target.name]: e.target.value
            }

        })

    }
    // tot hier

    const makeprofileEdit = () => {
        console.log(employeeValues)
        constructUserProfile(employeeValues, true)
    }

    const constructUserProfile = (list = employeeValues, edit = false) => {
        const form = document.getElementById("dataform")
        form.innerHTML = "";
        console.log(list)
        //add heading
        const headingelement = document.createElement("label")
        headingelement.className="formtitle"
        headingelement.innerText = t("My_personal_data");

        form.appendChild(headingelement);
        // <div className="formchild">
        //add Werknemer nummer

        const formelement = document.createElement("div");
        form.appendChild(formelement);
        const label = document.createElement("label");
        label.className = "label"
        formelement.appendChild(label);
        formelement.className = "formchild";
        label.innerText = "Wk nummer";
        const input = document.createElement("input");
        formelement.appendChild(input);
        input.value = list.nr_wkn;
        input.className="doublegrid"
        input.disabled = true;
        input.type = "text"

        //end add werknemer nummer



        const formelement2 = document.createElement("div");
        formelement2.className = "formchild";
        form.appendChild(formelement2)

        for (let [key, value] of Object.entries(list)) {
            if (key !== "id" && key !== "actief" && key !== "nr_wkn" && key!=="contract"&& key!=="uit_dienst") {
                const originalKey = key;
                key = capitalizeFirstLetter(key);
                const formelement = document.createElement("div");
                form.appendChild(formelement);
                const label = document.createElement("label");
                label.className = "label"
                formelement2.appendChild(label);
                label.innerText = key;
                const input = document.createElement("input");
                formelement2.appendChild(input);
                // formelement2.appendChild(formelement);
                input.id = key;
                input.className="doublegrid"
                input.addEventListener("input", e => { employeeValues[originalKey] = document.getElementById(key).value; console.log(employeeValues) })
                
                if ((key.toLowerCase().includes("datum") || key.toLowerCase().includes("date") || key.toLowerCase().includes("dienst"))) {
                    if (value !== null) {
                        value = new Date(value).toLocaleDateString('en-CA')
                    }

                    input.type = "date"
                    input.value = value;
                    if (!edit) {
                        input.disabled = true;
                    }
                    if (key === "In_dienst") {
                        input.disabled = true;
                    }
                    
                    

                } else {


                    input.value = value;
                    if (!edit) {

                        input.disabled = true;
                    }
                    if (key === "Rijksregisternummer") {
                        input.disabled = true;
                    }
                    
                    
                    input.type = "text"
                }
             
                
            }
            


        }

        if (edit) {
            edit = true;
            document.getElementById("editDataText").innerText = "Save data"
            document.getElementById("editData").removeEventListener("click", constructData)
            document.getElementById("editData").addEventListener("click", saveDate);
        }

    }


    const saveDate = () => {
        console.log("save Data")
        document.getElementById("editDataText").innerText = "Edit my data"
        document.getElementById("editData").removeEventListener("click", saveDate)
        document.getElementById("editData").addEventListener("click", constructData);
        console.log(employeeValues)
        constructUserProfile(employeeValues)

    }
    const constructData = () => {
        console.log("save Data")
        document.getElementById("editDataText").innerText = "Edit my data"
        document.getElementById("editData").removeEventListener("click", saveDate)
        document.getElementById("editData").addEventListener("click", constructUserProfile);
        console.log(employeeValues)
        constructUserProfile(employeeValues)

    }
    return (
        <>
            <div id="main" className="main">
                <header><div id="errordiv" className='error'></div><div className="checkinTime"><div id="timelabel" ></div><div id="time"></div></div></header>
                {/* <form id="dataform" className="form">
                

                </form> */}
                <form className="form" onSubmit={onSubmit}>
                        <label className='formtitle'>Mijn Gegevens</label>
                        <br></br>
                        <div className="formElement">
                            <label className='labelcolor'>
                                Firstname:
                            </label>
                            <input type='text' name="voornaam" value={user.voornaam} onChange={changevalue}></input>
                        </div>
                        <div className="formElement">
                            <label className='labelcolor'>
                                Lastname:
                            </label>
                            <input type='text' name="achternaam" value={user.achternaam} onChange={changevalue}></input>
                        </div>
                        <Tabs className="HotelTabs mydataTabs">
                            <TabList >
                                <Tab>Account</Tab>
                                <Tab>Address</Tab>
                                <Tab>Hotels</Tab>
                            </TabList>
                            <TabPanel >
                                <div className='tabpanel UserTabs' id="Account">
                                    <div className="formElement">
                                        <label>
                                            Email:
                                        </label>
                                        <input type='Email' name="email" value={user.email} onChange={changevalue}></input>
                                    </div>
                                    <div className="formElement">
                                        <label>
                                            Password:
                                        </label>
                                        <input type='password' name="password" value={user.password} onChange={changevalue}></input>
                                    </div>
                                    <div className="formElement">
                                        <label>
                                            Profile:
                                        </label>
                                        <select id='profile' name="rights">

                                            <option value={2}>Administrator</option>
                                            <option value={3}>Supervisor</option>
                                            <option value={1} selected>Cleaner</option>

                                        </select>
                                    </div>
<br></br>
                                    <div className="formElement">
                                        <label>
                                            Rijksregisternummer:
                                        </label>
                                        <input type='text' name="RRN" ></input>
                                    </div>
                                    <div className="formElement">
                                        <label>
                                            Bankrekening:
                                        </label>
                                        <input type='text' name="bankAccount" ></input>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel id="Address">
                                <div className='tabpanel UserTabs' >
                                    <div className="formElement">
                                        <label>
                                            Address line:
                                        </label>
                                        <input type='text' name="straatnaam" value={user.straatnaam} onChange={changevalue}></input>
                                    </div>
                                    <div className="formElement">
                                        <label>
                                            City:
                                        </label>
                                        <input type='text' name="gemeente" value={user.gemeente} onChange={changevalue}></input>
                                    </div>
                                    <div className="formElement">
                                        <label>
                                            Zip code:
                                        </label>
                                        <input type='number' name="huisnummer" value={user.huisnummer} onChange={changevalue}></input>
                                    </div>
                                    <div className="formElement">
                                        <label>
                                            Country:
                                        </label>
                                        <input type='text' name="land" value={user.land} onChange={changevalue}></input>
                                    </div>
                                    <div className="formElement">
                                        <label>
                                            Phone:
                                        </label>
                                        <input type='Phone'  name="gsm" value={user.gsm} onChange={changevalue}></input>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className='tabpanel UserTabs' >
                                    <div>
                                        <p>Hotels -- hier lijst van hotels waaraan de gebruiker is gelinked</p>
                                    </div>
                                </div>
                            </TabPanel>
                        </Tabs>
                        <div id="saveDiv" class="Four_Buttons noBackgroundcolor">
                            <Button type="submit" id="saveButton" class="Savebutton btn btn-primary margin-right">Save</Button>
                            <Button type="button" id="cancelButton" class="Savebutton btn btn-primary margin-right">Cancel</Button>
                            <Button type="button" id="editButton" class="Savebutton btn btn-primary margin-right">Edit</Button>
                        </div>
                    </form>
                {/* <div id="editData" className="buttoncontainer" >
                    <div id="editDataText" className="small_button">{t('EditMyData')}</div>
                </div>


                <div className="buttoncontainer">
                    <div className="small_button">{t('ShowMyContract')}</div>
                </div>

 */}

            </div>
        </>
    )
}
export default MyData