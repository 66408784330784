import './theme.scss';
import { Routes, Route } from "react-router-dom";
import { Login } from "./pages/login";
import { useState } from 'react'
import { Createhotel, Viewhotels, HotelView } from './pages/hotel'
import { ConstructEmployeePage, Addemployee } from './pages/employee';
import { checkloggedinstatus } from "./pages/login"
import { Constructcheckinpage } from './pages/checkinpage';
import { useEffect } from 'react';
import Startpage from "./pages/Startpage";
import Navigatie from "./pages/nav";
import Application from './pages/application';
import Cleaner from './pages/cleaner';
import MyData from './pages/mydata';
import Daystats from './pages/daystats';
import EmplopyeePage from './pages/EmplopyeePage';
import NotFound from './pages/errorPage';
import MyCleaningTasks from './pages/mycleaningtasks';
import Rooms from './pages/Rooms';
import Roomtype from './pages/Roomtype';
import Cleaningtype from './pages/Cleaningtype';
import Cleaningtask from './pages/Cleaningtask';
import MyHolidayRequest from './pages/myholidayrequest'
import AssignCleaningTasks from './pages/AssignCleaningTasks';
import CheckInAtWork from './pages/checkinatwork';
import Routeinghome from './pages/route';
import Ciaw from './pages/ciaw';
import Passwordreset from './pages/passwoordreset';
import { getversion } from './dbconnector/connector';

import { useTranslation } from 'react-i18next';
// import { getCookie } from './dbconnector/checktoken';

var mobile = require('is-mobile');


if (mobile()) {

  if (window.screen.width < 600) {
    import('./pages/css/App.mobilesmall.css')
  } else {
    import('./pages/css/App.mobile.css')
  }
  console.log(window.screen)
} else {
  import('./pages/css/App.css')
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
}


async function checkVersion() {
  const cookieVersion = getCookie("version");

  if (!cookieVersion) {
    console.error("Version not found in cookies.");
    return;
  }

  try {
    const response = await getversion();
    const apiVersion = String(response.version)

    console.log(apiVersion)
    if (cookieVersion.trim() !== apiVersion.trim()) {
      console.warn(`Version mismatch: Cookie (${cookieVersion}), API (${apiVersion})`);
      // Update the cookie to the new version
      setCookie("version", apiVersion, 7); // Set for 7 days (adjust as needed)
      console.log(`Cookie updated to new version: ${apiVersion}`);
      // Reload the page
      window.location.reload();
    } else {
      console.log(`Version matches: ${cookieVersion}`);
    }
  } catch (err) {
    console.error("Error fetching version from API:", err);
  }
}


// Helper function to set a cookie
function setCookie(name, value, days) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ""}${expires}; path=/`;
}

// Function to ensure 'version' cookie exists with default value of '0'
function ensureVersionCookie() {
  const cookieVersion = getCookie("version");
  if (!cookieVersion) {
    console.log("Version cookie not found. Setting default version to '0'.");
    setCookie("version", "0", 7); // Set to 7 days or adjust as needed
  } else {
    console.log(`Version cookie exists: ${cookieVersion}`);
  }
}

// Run the function to ensure 'version' cookie exists
ensureVersionCookie();



// Set an interval to check version every 1 minute
setInterval(checkVersion, 60 * 1000);
// setInterval(checkVersion, 60);



function App() {

  const [loggedIn, setLoggedIn] = useState(false)
  const [email, setEmail] = useState('')
  const [rights, setRights] = useState('')
  const { t } = useTranslation();




  useEffect(() => {
    const tokenresponse = checkloggedinstatus()
    tokenresponse.then(response => {
      if (response) {
        if (response.email) {
          setRights(response.rights)
          setEmail(response.email);
          setLoggedIn(true);
        } else {
          setLoggedIn(false);
          setEmail("");
        }
      } else {
        setLoggedIn(false);
        setEmail("");
      }
    })





  }, []);



  return (
    <>

      <div>

        <div id="TopNavigatie" className="TopNavigatie"><Navigatie loggedin={loggedIn} setrights={setRights} rights={rights} translation={t}></Navigatie></div>
        <div className="LeftMenu">
          {/* <Menu></Menu> */}
          <div className="LeftTop" href="/home"><a href="/" ><img src="./img/logo_stars.png" alt="" /></a></div>
        </div>
      </div>
      <div className="App" id="App">
        {rights === 2 ? (
          <Routes>
            <Route
              path="/"
              element={<Application loggedIn={loggedIn} email={email} rights={rights} translation={t} />}
            />
            <Route path="/ciaw" element={<Ciaw loggedIn={loggedIn} email={email} rights={rights} translation={t} />} />
            <Route path="/createhotel" element={<Createhotel loggedIn={loggedIn} email={email} rights={rights} translation={t} />} />
            <Route path="/hotels" element={<Viewhotels rights={rights} translation={t} />} />
            <Route path="/Rooms" element={<Rooms rights={rights} translation={t} />} />
            <Route path="/Roomtype" element={<Roomtype rights={rights} translation={t} />} />
            <Route path="/Cleaningtype" element={<Cleaningtype rights={rights} translation={t} />} />
            <Route path="/Cleaningtask" element={<Cleaningtask rights={rights} translation={t} />} />
            <Route path="/hotels/:id" element={<HotelView rights={rights} translation={t} />} />
            <Route path="/employees" element={<ConstructEmployeePage rights={rights} translation={t} />} />
            <Route path="/create-employee" element={<Addemployee rights={rights} translation={t} />} />
            <Route path="/mydata" element={<MyData rights={rights} translation={t} />} />
            <Route path="/checkins" element={<Constructcheckinpage rights={rights} translation={t} />} />
            <Route path="/daystats" element={<Daystats rights={rights} translation={t} />} />
            <Route path="/employee/:id" element={<EmplopyeePage rights={rights} translation={t} />} />
            <Route path="/AssignCleaningTasks" element={<AssignCleaningTasks rights={rights} translation={t} />} />
            <Route path="/myholidayrequest" element={<MyHolidayRequest rights={rights} translation={t} />} />
            <Route path="/checkinatwork" element={<CheckInAtWork rights={rights} translation={t} />} />
            <Route path="*" element={<NotFound translation={t} />} />
          </Routes>

        ) : rights === 3 ? (
          <Routes>
            <Route
              path="/"
              element={<Application loggedIn={loggedIn} email={email} rights={rights} translation={t} />}
            />
            <Route path="/mydata" element={<MyData rights={rights} translation={t} />} />
            <Route path="/checkins" element={<Constructcheckinpage rights={rights} translation={t} />} />
            <Route path="/mycleaningtasks" element={<MyCleaningTasks rights={rights} translation={t} />} />
            <Route path="/AssignCleaningTasks" element={<AssignCleaningTasks rights={rights} translation={t} />} />
            <Route path="/myholidayrequest" element={<MyHolidayRequest rights={rights} translation={t} />} />
            <Route path="/checkinatwork" element={<CheckInAtWork rights={rights} translation={t} />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        ) : rights === 1 ? (
          <Routes>
            <Route
              path="/"
              element={<CheckInAtWork rights={rights} translation={t} />}
            />
            <Route
              path="/home"
              element={<Routeinghome rights={rights} translation={t} />}
            />
            <Route path="/mydata" element={<MyData rights={rights} translation={t} />} />
            <Route path="/checkins" element={<Constructcheckinpage rights={rights} translation={t} />} />
            <Route path="/mycleaningtasks" element={<MyCleaningTasks rights={rights} translation={t} />} />
            <Route path="/myholidayrequest" element={<MyHolidayRequest rights={rights} translation={t} />} />
            <Route path="/AssignCleaningTasks" element={<AssignCleaningTasks rights={rights} translation={t} />} />
            <Route path="/checkinatwork" element={<CheckInAtWork rights={rights} translation={t} />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        ) : <Routes>
          <Route
            path="/"
            element={<Startpage email={email} loggedIn={loggedIn} rights={rights} setLoggedIn={setLoggedIn} />}
          />
          <Route path="/login" element={<Login setLoggedIn={setLoggedIn} setEmail={setEmail} setRights={setRights} rights={rights} />} />
          <Route path="/passReset" element={<Passwordreset />} />
          <Route path="*" element={<NotFound />} />
        </Routes>}


      </div>


    </>
  );
}



export default App;
