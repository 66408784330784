import { setErrorMessage } from "../tools/errorhandling";
import { useEffect } from 'react';

const NotFound = () => {
    return (
        <div id="main">
            <div id="errordiv" className='error'>Page not found</div>
        </div>

    )

}

export default NotFound