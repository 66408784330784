import React from "react"
import { useNavigate } from 'react-router-dom'

// import "../pages/css/accordion.scss";
import { useEffect } from 'react';
import { setErrorMessage } from "../tools/errorhandling";
import { myCleaningTasks } from "../dbconnector/connector";
import { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';





function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}



const MyCleaningTasks = () => {

    useEffect(() => {

        try {
            // call on backend here if so that app does not crash and spews error code

            document.getElementById("IconCleaning").style.display = "visible";
            document.getElementById("IconMyData").style.display = "none";
            document.getElementById("IconMyHoliday").style.display = "none";
            document.getElementById("IconMyContract").style.display = "none";

            //simulatie van backendcall zodat code werkt
            const data = async () => {

            };

            //wachten op response van server. code binnen de brackets uitvoeren wanner de response er is (=callback function)
            data.then(response => {
                if (response === 200) {
                    //dosomething with data
                    const contentdiv = document.getElementById("content")
                } else {
                    throw new Error(response.status + ":" + response.message)
                }
            })
        } catch (error) {
            setErrorMessage(error.message)
        }


    }, []);
    return (
        <>
            <div id="main" className="main">
                <header><div id="errordiv" className='error'></div><div className="checkinTime"><div id="timelabel" ></div><div id="time"></div></div></header>
                <Accordion>
                    <Accordion.Item eventKey="0" className="CleaningTask">
                        <Accordion.Header>Room 253 : Suite : Vertrekker</Accordion.Header>
                        <Accordion.Body>

                            <div id="Cleaning_subtasks" >
                                <ul>
                                    <li>Stofzuigen</li>
                                    <li>Bed opmaken</li>
                                    <li>Keuken poetsen</li>
                                    <li>Handdoeken vervangen</li>
                                    <br></br>
                                    <li>Extra taken
                                        <ul>
                                            <li>afwas doen</li>
                                            <li>lakens verschonen</li>
                                        </ul>

                                    </li>
                                </ul>
                            </div>
                            <div id="startjob" className="taskbuttoncontainer" >
                                <div className="small_button">Start Cleaning Job</div>
                            </div>
                            {/* <div id="stopjob" className="taskbuttoncontainer" >
                                <div className="small_button">Stop Cleaning Job</div>
                            </div> */}
                            <Accordion>
                                <Accordion.Item eventKey="0" className="ReportIssue">
                                    <Accordion.Header>Report Issue</Accordion.Header>
                                    <Accordion.Body>
                                        <label>Description</label>
                                        <textarea id="IssueReportDescription"></textarea>
                                        <div id="startjob" className="taskbuttoncontainer" >
                                            <div className="small_button">Add Picture</div>
                                        </div>

                                        <div id="technicalIssue" className="taskbuttoncontainer" >
                                            <div className="small_button">Save issue</div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Accordion.Body>

                    </Accordion.Item>

                </Accordion>
                &nbsp;
                <Accordion >
                    <Accordion.Item eventKey="0" className="CleaningTask">
                        <Accordion.Header>Room 254 : Kamer : Blijver : 30 min</Accordion.Header>
                        <Accordion.Body>

                            <div id="Cleaning_subtasks" >
                                <ul>
                                    <li>Stofzuigen</li>
                                    <li>Bed opmaken</li>
                                    <li>Handdoeken vervangen</li>

                                </ul>
                            </div>
                            <div id="startjob" className="taskbuttoncontainer" >
                                <div className="small_button">Start Cleaning Job</div>
                            </div>
                            {/* <div id="stopjob" className="taskbuttoncontainer" >
                                <div className="small_button">Stop Cleaning Job</div>
                            </div> */}
                            <Accordion>
                                <Accordion.Item eventKey="0" className="ReportIssue">
                                    <Accordion.Header>Report Issue</Accordion.Header>
                                    <Accordion.Body>
                                        <label>Description</label>
                                        <textarea id="IssueReportDescription"></textarea>
                                        <div id="startjob" className="taskbuttoncontainer" >
                                            <div className="small_button">Add Picture</div>
                                        </div>

                                        <div id="technicalIssue" className="taskbuttoncontainer" >
                                            <div className="small_button">Save issue</div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>


                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                &nbsp;
                <Accordion >
                    <Accordion.Item eventKey="0" className="CleaningTask">
                        <Accordion.Header>Lobby : variabele taak : 60 min</Accordion.Header>
                        <Accordion.Body>

                            <div id="Cleaning_subtasks" >
                                <ul>
                                    <li>Stofzuigen</li>
                                    <li>Bed opmaken</li>
                                    <li>Handdoeken vervangen</li>

                                </ul>
                            </div>
                            <div id="startjob" className="taskbuttoncontainer" >
                                <div className="small_button">Start Cleaning Job</div>
                            </div>
                            {/* <div id="stopjob" className="taskbuttoncontainer" >
                                <div className="small_button">Stop Cleaning Job</div>
                            </div> */}
                            <Accordion>
                                <Accordion.Item eventKey="0" className="ReportIssue">
                                    <Accordion.Header>Report Issue</Accordion.Header>
                                    <Accordion.Body>
                                        <label>Description</label>
                                        <textarea id="IssueReportDescription"></textarea>
                                        <div id="startjob" className="taskbuttoncontainer" >
                                            <div className="small_button">Add Picture</div>
                                        </div>

                                        <div id="technicalIssue" className="taskbuttoncontainer" >
                                            <div className="small_button">Save issue</div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </>
    )
}
export default MyCleaningTasks
