import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './css/tabs.css';


import { sendemployeedata, getemployees } from "../dbconnector/connector";
import { useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { setErrorMessage } from "../tools/errorhandling";
import Button from 'react-bootstrap/Button';
import { MdEdit, MdCheck } from "react-icons/md";



const createlist = (list, navigate) => {
    let divlist = document.getElementById("list")


    if (divlist === null) {
        let divlistheader = document.getElementById("resultlistHeader")
        const upperdiv = document.getElementById("searchbox");//resultlist
        const resultdiv = document.getElementById("resultlist")
        const newuserdiv = document.getElementById("newuser")

        divlist = document.createElement("div");
        divlist.id = "list"

        const zoekbalk = document.createElement("input");
        console.log("append zoekbalk")
        zoekbalk.type = "text"
        zoekbalk.id = "Zoekbalk"
        zoekbalk.placeholder = "Search"

        const activelabel = document.createElement("label");
        activelabel.id = "activelabel"
        activelabel.className = "labelcolor"
        activelabel.innerText = "Actief :"


        const active = document.createElement("input");
        console.log("append active")
        active.type = "checkbox"
        active.className = "checkbox"
        active.checked = "true"
        active.label = "checkbox"
        active.id = "active"


        const addbutton = document.createElement("a");
        addbutton.id = "addbutton";
        addbutton.className = "btn btn-primary";
        addbutton.href = "/create-employee";
        addbutton.innerText = "New user"

        active.addEventListener("click", function (e) {
            const zoekbalk = document.getElementById("Zoekbalk")
            const filteredlist = list.filter(element => {
                if ((element.voornaam + " " + element.achternaam).toLowerCase().includes(zoekbalk.value.toLowerCase()) || element.rijksregisternummer.includes(zoekbalk.value.toLowerCase())) 
                {
                    return element
                }
            })
            createlist(filteredlist, navigate)
        })

        zoekbalk.addEventListener("input", function (e) {
            const filteredlist = list.filter(element => {
                if ((element.voornaam + " " + element.achternaam).toLowerCase().includes(e.target.value.toLowerCase()) || element.rijksregisternummer.includes(e.target.value.toLowerCase())) 
                {
                    return element
                }
            })
            createlist(filteredlist, navigate)
        })
        upperdiv.appendChild(zoekbalk);
        upperdiv.appendChild(activelabel);
        upperdiv.appendChild(active);

        newuserdiv.appendChild(addbutton);
        resultdiv.appendChild(divlist);

    } else {
        divlist.innerHTML = "";

    }

    console.log(document.getElementById("active").checked)
    const newactivelist = list.filter(element => !document.getElementById("active").checked?element.actief===0:element.actief===1)

    newactivelist.forEach((element, index) => {
        //create wrapper employeediv
        const employediv = document.createElement("div")
        divlist.appendChild(employediv)

        const EMP_ID_div = document.createElement("p")
        EMP_ID_div.innerText = element.nr_wkn
        employediv.appendChild(EMP_ID_div)


        const name = document.createElement("p")
        employediv.appendChild(name)
        name.innerText = element.voornaam + " " + element.achternaam


        const phone = document.createElement("p")
        phone.innerText = element.gsm
        employediv.appendChild(phone)

        //dinamicly add classname
        if (index % 2 === 0) {
            employediv.className = "linkVarient1 gridEmployees"
        } else {
            employediv.className = "linkVarient2 gridEmployees"
        }
        employediv.addEventListener('click', function (e) {
            navigate("/employee/" + element.id);
        });

    });

}



const ConstructEmployeePage = () => {



    const [employeelist, setEmployeelist] = useState('');

    const navigate = useNavigate();



    useEffect(() => {

        try {

            const employees = getemployees();

            
            employees.then((result) => {
                console.log("employees", result)
                if (result.status === 200) {
                    setEmployeelist(result)
                    createlist(result.items, navigate)
                } else {
                    setErrorMessage(result.status + ": " + result.message)
                }

            })

        } catch (error) {
            setErrorMessage("error while retrieving employee list")
        }


    }, []);





    return (
        <div id="main">
            <header>
                <div id="errordiv" className='error'>
                </div>
                <div className="checkinTime">
                    <div id="timelabel" >
                    </div>
                    <div id="time">
                    </div>
                </div>
            </header>
            <div id="newuser" className="form">

            </div>
            <div id="filters" className="form" >
                <label className='formtitleContentBox'>filter instellingen</label>
                <br></br>
                <div className="contentbox ">
                    <div id="searchbox" className="formElement">
                        <label className='labelcolor'>
                            Gebruiker:
                        </label>
                    </div>
                </div>
                <div id="headertable" className="listheader"><p id="ID">ID</p><p id="Fullname">Fullname</p><p id="Phone">Phone</p></div>
                {/* <div id="resultlistHeader" className="listheader">

                </div> */}
                <div id="resultlist" className='list'>

                </div>
            </div>


        </div>
    )

}




const Addemployee = () => {


    const [user, setUser] = useState({});
    const navigate = useNavigate()
    const [tabsDisabled, setTabsDisabled] = useState(false);



    useEffect(() => {
        setTabsDisabled(true);
    }, []);


    const changevalue = (e) => {

        setUser((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }

        })

    }
    const changecheckbox = (e) => {

        setUser((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.checked
            }

        })

    }

    const onSubmit = (e) => {
        e.preventDefault();
        const response = sendemployeedata(user);
        console.log(response)
        response.then((res) => {
            console.log(res)
            if (res.status === 200) {
                setTabsDisabled(false);
                navigate('/employees');
            } else {
                setErrorMessage(res.statusText)
            }

        })
    }
    const navigateback = () => {
        navigate('/employees')
    }

    return (
        <div id="main">
            <header><div id="errordiv" className='error'></div><div className="checkinTime"><div id="timelabel" ></div><div id="time"></div></div></header>
            <div id="return" className="form">
                <Button type="button" id="cancelButton" class="Savebutton btn btn-primary margin-right" onClick={navigateback} >Return to list</Button>
            </div>
            <form className="form" onSubmit={onSubmit} autoComplete='off'>
                <label className='formtitleContentBox'>Algemene gegevens</label>
                <br></br>
                <div className="contentbox">
                    <div className="formElement">
                        <label className='labelcolor'>
                            Actief:
                        </label>
                        <input id="userActive" type='CheckBox' className="checkbox" value={user.actief} name="actief" onChange={changecheckbox} checked={user.actief}></input>
                    </div>
                    <div className="formElement">
                        <label className='labelcolor'>
                            Voornaam:
                        </label>
                        <input type='text' name="voornaam" value={user.voornaam} onChange={changevalue}></input>
                    </div>

                    <div className="formElement">
                        <label className='labelcolor'>
                            Achternaam:
                        </label>
                        <input type='text' name="achternaam" value={user.achternaam} onChange={changevalue}></input>
                    </div>
                    <div className="formElement">
                        <label className='labelcolor'>
                            nr wkn:
                        </label>
                        <input type='number' name="nr_wkn" value={user.nr_wkn} onChange={changevalue}></input>
                    </div>
                </div>
                <br></br>
                <Tabs className="HotelTabs">
                    <TabList >
                        <Tab>Account</Tab>
                        <Tab>Persoons info</Tab>
                        <Tab>Adres gegevens</Tab>
                        <Tab disabled={tabsDisabled}>Hotels</Tab>
                    </TabList>
                    <TabPanel >
                        <div className='tabpanel UserTabs' id="Account">
                            <div className="formElement">
                                <label>
                                    Email:
                                </label>
                                <input type='Email' name="email" value={user.email} onChange={changevalue} autocomplete="off"></input>
                            </div>
                            <div className="formElement">
                                <label>
                                    Paswoord:
                                </label>
                                <input type='password' name="paswoord" value={user.paswoord} onChange={changevalue} autocomplete="off"></input>
                            </div>
                            <div className="formElement">
                                <label>
                                    Profiel:
                                </label>
                                <select id='profile' name="FK_Rechten_ID" onChange={changevalue} required>
                                    <option ></option>
                                    <option value={2}>Administrator</option>
                                    <option value={3}>Supervisor</option>
                                    <option value={1} >Cleaner</option>
                                </select>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel >
                        <div className='tabpanel UserTabs' id="Extra info">
                            <div className="formElement">
                                <label>
                                    rijksregisternummer:
                                </label>
                                <input type='text' name="rijksregisternummer" value={user.rijksregisternummer} onChange={changevalue}></input>
                            </div>
                            <div className="formElement">
                                <label>
                                    Geboorte datum:
                                </label>
                                <input type='Date' name="geboortedatum" value={user.geboortedatum} onChange={changevalue}></input>
                            </div>
                            <div className="formElement">
                                <label>
                                    in_dienst:
                                </label>
                                <input type='Date' name="in_dienst" value={user.in_dienst} onChange={changevalue}></input>
                            </div>
                            <div className="formElement">
                                <label>
                                    uit dienst:
                                </label>
                                <input type='Date' name="uit_dienst" value={user.uit_dienst} onChange={changevalue}></input>
                            </div>
                            <div className="formElement">
                                <label>
                                    afdeling nummer:
                                </label>
                                <input type='number' name="nr_afd" value={user.nr_afd} onChange={changevalue}></input>
                            </div>
                            <div id="uploaddiv" className="formElement">
                                <label >
                                    Upload Contract:
                                </label>
                                <input id="uploadContract" type='file' accept='.pdf' ></input>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel id="Address">
                        <div className='tabpanel UserTabs' >
                            <div className="formElement">
                                <label>
                                    Straat naam:
                                </label>
                                <input type='text' name="straatnaam" value={user.straatnaam} onChange={changevalue}></input>
                            </div>
                            <div className="formElement gridform4Cols">
                                <label>
                                    huisnummer:
                                </label>
                                <input type='text' className="halfwidth" name="huisnummer" value={user.huisnummer} onChange={changevalue}></input>
                                <label>
                                    Postbus:
                                </label>
                                <input type='text' className="halfwidth" name="bus" value={user.bus} onChange={changevalue}></input>
                            </div>
                            <div className="formElement">
                                <label>
                                    Gemeente:
                                </label>
                                <input type='text' name="gemeente" value={user.gemeente} onChange={changevalue}></input>
                            </div>
                            <div className="formElement">
                                <label>
                                    Zip code:
                                </label>
                                <input type='number' name="zipcode" value={user.zipcode} onChange={changevalue}></input>
                            </div>
                            <div className="formElement">
                                <label>
                                    Land:
                                </label>
                                <input type='text' name="land" value={user.land} onChange={changevalue}></input>
                            </div>
                            <div className="formElement">
                                <label>
                                    Telefoon:
                                </label>
                                <input type='Phone' name="gsm" value={user.gsm} onChange={changevalue}></input>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className='tabpanel UserTabs' >
                            <div>
                                <p>Hotels -- hier lijst van hotels met een checkbox om te linken aan user of niet</p>
                            </div>
                        </div>
                    </TabPanel>
                </Tabs>

                <div id="saveDiv" class="two_Buttons">
                    <Button type="submit" id="saveButton" class="Savebutton btn btn-primary margin-right">Save</Button>
                    <Button type="button" id="cancelButton" class="Savebutton btn btn-primary margin-right" onClick={navigateback}>Cancel</Button>

                </div>
            </form>
        </div>
    )
}
export { ConstructEmployeePage, Addemployee }