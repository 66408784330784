import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getCookie } from "./dbconnector/checktoken";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            "checkinTitle": "Recent checkins:",
            "cleanerNav": "CLEANER",
            "LOGOUTNAV": "LOG-OUT",
            "MyCleaningJobs": "My assignments",
            "MyData": "My data",
            "MyContract": "My contract",
            "RequestHolliday": "Request holliday",
            "Pause": "Pause",
            "Pausestart": "Start Pause",
            "Pausestop": "Stop Pause",
            "CheckIn": "Check in",
            "CheckOut": "Check out",
            "pauseEnd": "Pause ended -",
            "Pausestarted": "Pause started -",
            "EditMyData": "Edit my data",
            "ShowMyContract": "Show my contract",
            "My_personal_data":"My data"
        }
    },
    nl: {
        translation: {
            "checkinTitle": "Recente aanmeldingen:",
            "cleanerNav": "SCHOONMAKER",
            "LOGOUTNAV": "UITLOGGEN",
            "MyCleaningJobs": "Mijn opdrachten",
            "MyData": "Mijn gegevens",
            "MyContract": "Mijn contract",
            "RequestHolliday": "Verlof aanvragen",
            "Pause": "Pauze",
            "Pausestart": "Start Pauze",
            "Pausestop": "Stop Pauze",
            "CheckIn": "Inchecken",
            "CheckOut": "Uitchecken",
            "pauseEnd": "Pauze beëindigd -",
            "Pausestarted": "Pauze gestart -",
            "EditMyData": "aanpassen",
            "ShowMyContract": "Mijn contract"
        }
    }, pt: {
        translation: {
            "checkinTitle": "Check-ins recentes:",
            "cleanerNav": "LIMPEZA",
            "LOGOUTNAV": "SAIR",
            "MyCleaningJobs": "Minhas atribuições",
            "MyData": "Meus dados",
            "MyContract": "Meu contrato",
            "RequestHolliday": "Solicitar férias",
            "Pause": "Pausa",
            "Pausestart": "Start Pausa",
            "Pausestop": "Stop Pausa",
            "CheckIn": "Check-in",
            "CheckOut": "Check-out",
            "pauseEnd": "Pausa encerrada -",
            "Pausestarted": "Pausa iniciada -",
            "EditMyData": "Editar meus dados",
            "ShowMyContract": "Mostrar meu contrato"

        }
    }, bg: {
        translation: {
            "checkinTitle": "Скорошни чекирания:",
            "cleanerNav": "ПОЧИСТВАНЕ",
            "LOGOUTNAV": "ИЗХОД",
            "MyCleaningJobs": "Моите задачи",
            "MyData": "Моите данни",
            "MyContract": "Моят договор",
            "RequestHolliday": "Заявка за отпуска",
            "Pause": "Пауза",
            "Pausestart": "Start Пауза",
            "Pausestop": "Stop Пауза",
            "CheckIn": "Чекиране",
            "CheckOut": "Отписване",
            "pauseEnd": "Краят на паузата -",
            "Pausestarted": "Пауза започната -",
            "EditMyData": "Редактирай моите данни",
            "ShowMyContract": "Покажи моя договор"
        }

    }, es: {
        translation: {
            "checkinTitle": "Check-ins recientes:",
            "cleanerNav": "LIMPIEZA",
            "LOGOUTNAV": "CERRAR SESIÓN",
            "MyCleaningJobs": "Mis tareas",
            "MyData": "Mis datos",
            "MyContract": "Mi contrato",
            "RequestHolliday": "Solicitar vacaciones",
            "Pause": "Pausa",
            "Pausestart": "Start Pausa",
            "Pausestop": "Stop Pausa",
            "CheckIn": "Registro de entrada",
            "CheckOut": "Registro de salida",
            "pauseEnd": "Pausa finalizada -",
            "Pausestarted": "Pausa iniciada -",
            "EditMyData": "Editar mis datos",
            "ShowMyContract": "Mostrar mi contrato"
        }

    }, tr: {
        translation: {
            "checkinTitle": "Son girişler:",
            "cleanerNav": "TEMİZLEYİCİ",
            "LOGOUTNAV": "ÇIKIŞ YAP",
            "MyCleaningJobs": "Görevlerim",
            "MyData": "Verilerim",
            "MyContract": "Sözleşmem",
            "RequestHolliday": "Tatil iste",
            "Pause": "Ara",
            "Pausestart": "Start Ara",
            "Pausestop": "Stop Ara",
            "CheckIn": "Giriş yap",
            "CheckOut": "Çıkış yap",
            "pauseEnd": "Ara sona erdi -",
            "Pausestarted": "Ara başladı -",
            "EditMyData": "Verilerimi düzenle",
            "ShowMyContract": "Sözleşmemi göster"
        }

    }, fr: {
        translation: {
            "checkinTitle": "Dernières vérifications :",
            "cleanerNav": "NETTOYEUR",
            "LOGOUTNAV": "DÉCONNEXION",
            "MyCleaningJobs": "Mes affectations",
            "MyData": "Mes données",
            "MyContract": "Mon contrat",
            "RequestHolliday": "Demande de congé",
            "Pause": "Pause",
            "Pausestart": "Start Pause",
            "Pausestop": "Arret Pause",
            "CheckIn": "Enregistrement",
            "CheckOut": "Départ",
            "pauseEnd": "Fin de pause -",
            "Pausestarted": "Début de pause -",
            "EditMyData": "Modifier mes données",
            "ShowMyContract": "Afficher mon contrat"
        }


    }
};
const code = getCookie("language")
if (code !== "") {

    i18n
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
            resources,
            lng: code,

            interpolation: {
                escapeValue: false // react already safes from xss
            }
        });

} else {
    i18n
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
            resources,
            lng: "en",

            interpolation: {
                escapeValue: false // react already safes from xss
            }
        });

}



export default i18n;