import React from "react"
import './css/createhotel.css';

import { daystats } from "../dbconnector/connector";

import { useEffect } from 'react';
import { setErrorMessage } from "../tools/errorhandling";



const createcheckinlist = (list) => {


    let divlist = document.getElementById("list")
    divlist.innerHTML = ""

    /* #region  creating headings */
    const employediv = document.createElement("div")
    divlist.appendChild(employediv)

    const type = document.createElement("p")
    employediv.appendChild(type)
    type.innerText = "Type"


    const EMP_name_div = document.createElement("p")
    EMP_name_div.innerText = "Name"
    employediv.appendChild(EMP_name_div)


    const TimeElement = document.createElement("p")
    TimeElement.innerText = "Time"
    employediv.appendChild(TimeElement);
    const day = document.createElement("p")
    day.innerText = "Date"
    employediv.appendChild(day)


    employediv.className = "tableheader griddaystatsList"


    /* #endregion */
    //create a list item for every element in the received array
    list.forEach((element, index) => {
        //create wrapper employeediv
        const employediv = document.createElement("div")
        divlist.appendChild(employediv)


        const type = document.createElement("p")
        employediv.appendChild(type)
        type.innerText = element.type


        const EMP_name_div = document.createElement("p")
        EMP_name_div.innerText = element.voornaam + " " + element.achternaam
        employediv.appendChild(EMP_name_div)


        const hours = element.time / 1000 / 60 / 60
        const minutes = (hours - Math.floor(hours)) * 60
        const seconds = (minutes - Math.floor(minutes)) * 60
        const timeElement = document.createElement("p")
        timeElement.innerText = Math.floor(hours) + "h " + Math.floor(minutes) + "m " + Math.floor(seconds) + "s"
        employediv.appendChild(timeElement);


        const day = document.createElement("p")
        day.innerText = new Date(element.loginDateTime).toLocaleDateString()
        employediv.appendChild(day)


        //dynamicaly add classname
        if (index % 2 === 0) {
            employediv.className = "linkVarient1 griddaystatsList"
        } else {
            employediv.className = "linkVarient2 griddaystatsList"
        }

    });

}

const Daystats = (props) => {

    let stats = daystats()
    useEffect(() => {
        try {

            stats.then(res => {
                const list = document.getElementById("list")
                list.innerHTML = ""
                createcheckinlist(res.daystats)


                const names = res.daystats.map(e => e.voornaam + " " + e.achternaam);
                const unique = names.filter((value, index, array) => array.indexOf(value) === index);
                const selection = document.getElementById("selection");
                selection.innerHTML = "";
                const option = document.createElement("option")
                option.innerText = " ";
                option.value = " ";
                selection.appendChild(option)

                for (const name of unique) {
                    const option = document.createElement("option")
                    option.innerText = name;
                    option.value = name;
                    selection.appendChild(option)
                }

            }

            );
        } catch (e) {
            setErrorMessage("Failed to retrieve Daystats")
        }

    }, []);

    const filterlist = () => {
        stats.then(res => {
            const dayvalue = document.getElementById("datefilter").value
            const selectionvalue = document.getElementById("selection").value
            let list = res.daystats;
            if (dayvalue) {
                list = list.filter(e => new Date(e.loginDateTime).toLocaleDateString('en-CA') === dayvalue)
            }
            if (selectionvalue && selectionvalue !== " ") {
                list = list.filter(e => (e.voornaam + " " + e.achternaam) === selectionvalue)
            }
            createcheckinlist(list)
        })


    }

    return (
        <>

            <div id="main" className="Screendiv">

                <div>
                    <div id="errordiv" className='error'></div>
                    <div id="filters" className="form" >
                        <label className='formtitleContentBox'>filter instellingen</label>
                        <br></br>
                        <div className="contentbox">
                            <div className="formElement">
                                <label className='labelcolor'>
                                    Datum:
                                </label>
                                <input id="datefilter" type="date" onChange={() => { filterlist() }}></input>
                            </div>
                            <div className="formElement">
                                <label className='labelcolor'>
                                    Cleaner:
                                </label>
                                <select id="selection" className="filterTypeSelect" onChange={() => { filterlist() }} >

                                </select>
                            </div>
                        </div>
                        <div id="list" className="list">

                        </div>
                    </div>
                </div>




            </div>

        </>
    )
}

export default Daystats