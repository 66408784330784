
import React from "react"
import {sendPassReset } from "../dbconnector/connector";
import Button from 'react-bootstrap/Button';




const onSubmit=(e)=>{
    e.preventDefault()
console.log("send password")
    sendPassReset(document.getElementById("email").value)
}

const Passwordreset = () => {



    return (
        <>
            <div id="main">
                <header>
                    <div id="errordiv" className='error'></div>
                </header>
                <div id="content">
                    <form className="form" onSubmit={onSubmit} >
                        <div className="Roomformchild">
                            <label className='labelhotel'>
                                Emailaddress:
                            </label>
                            <input id="email" name="email" className="normalinput" type='text' ></input>
                            </div>
                        <div className="two_Buttons">
                            <Button id="passwordresetButton" type="submit" className='Savebutton' >send password reset</Button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )

}

export default Passwordreset