import React from 'react'
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useEffect } from 'react';
import "/node_modules/flag-icons/css/flag-icons.min.css";




function FlagIcon({countryCode = ""}) {

    if (countryCode === "en") {
        countryCode = "gb";
    }

    return (
        <span
            className={`fi fis fiCircle inline-block mr-2 fi-${countryCode}`}
        />
    );
}

const languages = [
    { code: "en", lang: "English" },
    { code: "nl", lang: "Nederlands" },
    { code: "pt", lang: "Português" },
    { code: "bg", lang: "български" },
    { code: "es", lang: "Español" },
    { code: "fr", lang: "Français" },
    { code: "tr", lang: "Türkçe" }
]

const handleLanguageChange = (code,i18n) => {
    console.log(code)
    document.cookie = "language=" + code;
    
    i18n.changeLanguage(code, (err, t) => {
        if (err) return console.log('something went wrong loading', err);
        t('key'); // -> same as i18next.t
      });
      

}
const Languageselector = () => {
    const { i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const selectedLanguage = languages.find(language => language.code === i18n.language);

    const LANGUAGE_SELECTOR_ID = 'language-selector';


    useEffect(() => {
        const handleWindowClick = (event) => {
            const target = event.target.closest('button');
            if (target && target.id === LANGUAGE_SELECTOR_ID) {
                return;
            }
            setIsOpen(false);
        }
        window.addEventListener('click', handleWindowClick)
        return () => {
            window.removeEventListener('click', handleWindowClick);
        }
    }, []);
    return (<>
        <button
            onClick={() => setIsOpen(!isOpen)}
            type="button"
            className="langaugeopenbutton"
            id={LANGUAGE_SELECTOR_ID}
            aria-expanded={isOpen}
        >
            <FlagIcon countryCode={selectedLanguage.code}/>
            {/* {selectedLanguage.lang} */}
            <svg
                className="svgsize"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
            >
                <path
                    fillRule="evenodd"
                    d="M10.293 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L10 12.586l3.293-3.293a1 1 0 011.414 1.414l-4 4z"
                    clipRule="evenodd"
                />
            </svg>
        </button>
        {isOpen && <div
            className="languageselector"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby={LANGUAGE_SELECTOR_ID}
        >
            <div className="languagegrid" role="none">
                {languages.map((language, index) => {
                    return (
                        <button
                            key={language.code}
                            onClick={() => {
                                handleLanguageChange(language.code,i18n);
                                window.location.reload();
                            }}
                            className={`${selectedLanguage.code === language.code
                                ? "languageSelected"
                                : "langaugeNotSelected"
                                } otherlanguageStyling ${index % 2 === 0 ? 'language1' : 'language2'}`}
                            role="menuitem"
                        >
                            <FlagIcon countryCode={language.code}/>
                            <span className="truncate">{language.lang}</span>
                        </button>
                    );
                })}
            </div>
        </div>}
    </>
    )

}
export default Languageselector;